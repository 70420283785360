import React, { useEffect, useState } from "react"
import { ShareToGoCard } from "./ShareToGoCard";
import { ShareToGoBookingsSection } from "./ShareToGoBookingsSection";
import { ShareToGoService } from "../Services/Services";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingComponentWeb } from "./LoadingComponent";
import { getTranslationByKey } from "../global";
import Markdown from "markdown-to-jsx";

export function ShareToGoBookings(props) {

    const S2GService = new ShareToGoService
    const [isLoading, setLoading] = useState(true)
    const [bookingSections, setBookingSections] = useState([]);
    const [hasPrevious, setHasPrevious] = useState(false);
    const [previousMonth, setPreviousMonth] = useState('');
    const [nextMonth, setNextMonth] = useState('');
    const [bookingsPrevList, setBookingsPrevList] = useState([]);
    const [bookingsPrevSections, setBookingsPrevSections] = useState([]);
    const [bookingsNextSections, setBookingsNextSections] = useState([]);
    const [expanded, setExpanded] = React.useState('');
    const [expandedCurrent, setExpandedCurrent] = React.useState('');
    const [expandedNext, setExpandedNext] = React.useState('');


    useEffect(() => {
        reload()
    }, [])

    const reload = () => {
        S2GService.getRidesNoData().then((data) => {
            setBookingSections(data.ride_groups)
            setHasPrevious(data.has_previous)
            setPreviousMonth(data.previous_month)
            setNextMonth(data.next_month)
            setExpandedNext('panelcurrent0')
            setExpandedCurrent('panelcurrent1')
            setLoading(false)
            props.setLoadingBookings(false)
        })
    }

    //expand accordion function
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    //expand accordion function
    const handleChangeStart = (panel, index) => (event, isExpanded) => {
        if (index == 0)
            setExpandedNext(isExpanded ? panel : false);
        else
            setExpandedCurrent(isExpanded ? panel : false);

    };

    const getRidesMonth = (year, month) => {
        const date = new Date(year, month - 1);  // 2009-11-10
        const m = date.toLocaleString('default', { month: 'long' });
        return m
    };

    const getNextMonthRides = (d) => {
        S2GService.getRides(d).then((data) => {
            setBookingsNextSections(data.ride_groups)
            setLoading(false)
            props.setLoadingBookings(false)
        })
    }

    const handleSeeMore = () => {
        props.setLoadingBookings(true)
        props.handleSeeMore(props.prevMonth)
    }

    return (
        <div className="share_to_go_sections_container">
            {isLoading || props.isLoadingBookings ?
                <LoadingComponentWeb />
                :
                <React.Fragment>
                    <div >
                        {bookingSections.length > 0 ?
                            bookingSections.map((bs, index) =>
                                <React.Fragment>
                                    <div className="share_to_go_section_line_title share_to_go_section_line_accordion">
                                        <div Style="text-transform: capitalize;" className="bold">{getRidesMonth(bs.month.year, bs.month.month)} {bs.month.year}</div>

                                        <div Style="font-size: 14px;"> <Markdown>{getTranslationByKey('SHARETOGO.MYBOOKINGS.RESERVATIONS').replace('{available_bookings}', bs.available_rides)}</Markdown></div>

                                    </div>
                                    <Accordion className="other_loc_accordions share2go_accordions" expanded={index == 0 ? expandedNext === 'panelcurrent' + index : expandedCurrent === 'panelcurrent' + index} onChange={handleChangeStart('panelcurrent' + index, index)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon fontSize="small" />}
                                            aria-controls={"panelcurrenta-content" + index}
                                            id={"panelcurrenta-header" + index}
                                            className={" menu_row offices_menu other_loc_accordion"}
                                        >
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <div className="accordion_details_container">
                                                <ShareToGoBookingsSection reload={reload} section={bs} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </React.Fragment>)
                            : ''}
                        {props.prevBookings.length > 0 ?
                            props.prevBookings.map((bs, index) =>
                                <React.Fragment>
                                    <div className="share_to_go_section_line_title share_to_go_section_line_accordion">
                                        <div Style="text-transform: capitalize;" className="bold">{getRidesMonth(bs.month.year, bs.month.month)} {bs.month.year}</div>
                                    </div>
                                    <Accordion className="other_loc_accordions share2go_accordions" expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon fontSize="small" />}
                                            aria-controls={"panel" + index + "a-content"}
                                            id={"panel" + index + "a-header"}
                                            className={" menu_row offices_menu other_loc_accordion"}
                                        >
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <div className="accordion_details_container">
                                                <ShareToGoBookingsSection reload={reload} section={bs} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </React.Fragment>
                            )
                            : ''}

                    </div>
                    <div className="share_to_go_see_more_container">
                        {props.hasPrevious ?
                            <div className="share_to_go_see_more bold pointer" Style="text-decoration: underline;" onClick={() => handleSeeMore()}>{getTranslationByKey('SHARETOGO.MYBOOKINGS.SEEMORE')}</div>
                            :
                            ''}
                    </div>
                </React.Fragment>
            }
        </div >
    )
}