import React, { useEffect, useState } from "react"
import { getTranslationByKey } from "../global"

export function ShareToGoDocumentCard(props) {

    return (
        <div className="share_to_go_documents_card" onClick={() => props.handleSelectedDocument(props.name, props.url)}>
            <div className="share_to_go_card_icon">
                <div className="share_to_go_document_icon"> </div>
            </div>
            <div>
                <div className="bold ">{getTranslationByKey(props.name)}</div>
                <div className="share_to_go_card_description">{getTranslationByKey(props.description)}</div>
            </div>
        </div>
    )
}