import React, { useEffect } from "react"
import { getTranslationByKey } from "../global"
import EventCountdownLeftbar from "./EventCountdownLeftbar"
import { DeskService, WellnessService } from "../Services/Services"
import { BackButton } from "./BackButton"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconMenuButton } from "./IconMenuButton"
import { CustomMenuButton } from "./CustomMenuButton"
import ScrollContainer from 'react-indiana-drag-scroll'
import { UseAlertMessage } from "./Alert"

export function Sidebar(props) {

    const [selectedKey, setSelectedKey] = React.useState(true)
    const [otherLocationsView, setOtherLocationsView] = React.useState(false)
    const [defaultOfficeView, setDefaultOfficeView] = React.useState(false)
    const [subMenu, setSubMenu] = React.useState(false)
    const [subMenuMap, setSubMenuMap] = React.useState([])
    const [defaultOffice, setDefaultOffice] = React.useState(null)
    const [defaultOtherLocation, setDefaultOtherLocation] = React.useState(null)
    const [selectedOtherLocation, setSelectedOtherLocation] = React.useState('')
    const [otherLocationOffices, setOtherLocationOffices] = React.useState(null)
    const [customPages, setCustomPages] = React.useState(null)
    const [hidden, setHidden] = React.useState(null)
    const [expanded, setExpanded] = React.useState('');
    const [selectedRegion, setSelectedRegion] = React.useState([]);
    const [selectedRegionIndex, setSelectedRegionIndex] = React.useState('');
    let ds = new DeskService()

    useEffect(() => {
        let selected = false
        let path = window.location.pathname
        let menu_index = 1
        let office_id_index = 2
        let module_index = 3
        //staging
        if (window.location.hostname === 'smartoffice.globalbit.gucci') {
            menu_index = 2
            office_id_index = 3
            module_index = 4
        }
        let menu = path.split('/')[menu_index]

        //routing managing
        if (menu === 'location') {
            let office_id = path.split('/')[office_id_index]
            let select_module = path.split('/')[module_index]
            let exists = false
            if (props.user.modules.my_office && props.user.modules.my_office.id == office_id) {
                openDefaultViewModuleSelect('menu', props.user.modules.my_office, select_module)
            }
            else if (props.user && props.user.modules && props.user.modules.offices) {
                props.user.modules.offices.map((o, ind) => {
                    o.locations.map((l) => {
                        if (o.is_default_region) {
                            setExpanded('panel' + ind)
                            setSelectedOtherLocation(o.region)
                            setSelectedRegionIndex(ind)
                            setSelectedRegion(o.locations)
                        }
                        if (l.id == office_id) {
                            exists = true
                            selected = true
                            openOtherLocationViewModuleSelect('menu', l, select_module)
                        }
                    })
                })
                if (!exists) {
                    UseAlertMessage(400, 'Location not available')
                }
            }

        } else {
            switch (menu) {
                case 'desk_booking':
                    if (props.user.modules.desk_booking) {
                        select('desk_booking', props.user.modules.desk_booking, 'desk_booking', 'menu', null)
                        selected = true
                    } else
                        UseAlertMessage(400, 'Module not available')
                    break;
                case 'ready_to_grow':
                    if (props.user.modules.ready_to_grow) {
                        select('ready_to_grow', props.user.modules.ready_to_grow, 'ready_to_grow_singlem', 'menu', null)
                        selected = true
                    } else
                        UseAlertMessage(400, 'Module not available')
                    break
                case 'bookshelf':
                    if (props.user.modules.bookshelf) {
                        select('Bookshelf', props.user.modules.bookshelf, 'bookshelf_singlem', 'menu', null)
                        selected = true
                    } else
                        UseAlertMessage(400, 'Module not available')
                    break
                case 'wellness_lab':
                    if (props.user.modules.wellness_lab) {
                        select('wellness', props.user.modules.wellness_lab, 'wellness', 'menu', null)
                        selected = true
                    } else
                        UseAlertMessage(400, 'Module not available')
                    break;
                case 'business_travel':
                    if (props.user.modules.custom_pages) {
                        let v = null
                        v = props.user.modules.custom_pages.filter((c) => c.key === 'business_travel')
                        if (v && v.length > 0) {
                            select('custom_page', v[0], 'custom', 'menu')
                            selected = true
                        }
                        else
                            UseAlertMessage(400, 'Module not available')
                    }
                    break;
                case 'changemakers':
                    if (props.user.modules.custom_pages) {
                        let v = null
                        v = props.user.modules.custom_pages.filter((c) => c.key === 'changemakers')
                        if (v && v.length > 0) {
                            select('custom_page', v[0], 'custom', 'menu')
                            selected = true
                        } else
                            UseAlertMessage(400, 'Module not available')
                    }
                    break;
                case 'feed':
                    if (props.user.modules.feed) {
                        select('feed', props.user.modules.feed, 'feed', 'menu', null)
                        selected = true
                        let routingObj = {
                            path: window.location.href
                        }
                        props.setRoutingObj(routingObj)
                    } else
                        UseAlertMessage(400, 'Module not available')
                    break;
                case 'opportunities':
                    if (path.split('/')[office_id_index] === 'feedback') {
                        selected = true
                        select('opportunities_feedback_form', props.user.modules.opportunities, 'opportunities_feedback_form', 'menu', null)
                    }
                    else if (props.user.modules.opportunities) {
                        select('opportunities', props.user.modules.opportunities, 'opportunities', 'menu', null)
                        selected = true
                        let routingObj = {
                            path: window.location.href
                        }
                        props.setRoutingObj(routingObj)
                    } else
                        UseAlertMessage(400, 'Module not available')
                    break;
                case 'event':

                    if (props.user.events && props.user.events.length > 0) {

                        let event_id = (path.split("/event/")[1])
                        let exists = false
                        props.user.events.map((e) => {
                            if (e.id == event_id || event_id.split('/')[0] == e.id) {
                                selected = true
                                props.select('custom_pages_event', e, 'custom_pages_event', 'menu')
                            }
                        })
                        if (!exists) {
                            UseAlertMessage(400, 'Event not available')
                            setHidden(false)
                        }

                        let routingObj = {
                            path: window.location.href
                        }
                        props.setRoutingObj(routingObj)
                        if (selected)
                            setTimeout(() => {
                                setHidden(true)
                            }, 500);
                    } else
                        UseAlertMessage(400, 'Module not available')
                    break;

                default:
                    break;
            }
        }
        //my and other offices set
        if (props.user.modules) {
            if (props.user.modules.my_office) {
                setDefaultOffice(props.user.modules.my_office)
            }
            if (props.user.modules.offices) {
                let def = false
                props.user.modules.offices.map((o, ind) => {
                    if (o.is_default_region) {
                        def = true
                        setSelectedOtherLocation(o.region)
                        setSelectedRegionIndex(ind)
                        setSelectedRegion(o.locations)
                    }
                    setOtherLocationOffices(props.user.modules.offices)
                })
                if (!def && props.user.modules.offices.length > 0) {
                    setSelectedRegionIndex(0)
                    setSelectedRegion(props.user.modules.offices[0].locations)
                    props.setPathlistValue(null)
                }
            }
        }
        //staging
        if (window.location.hostname === 'smartoffice.globalbit.gucci')
            window.history.pushState({ page: "display_page" }, "display_page", '/staging');
        else
            window.history.pushState({ page: "display_page" }, "display_page", '/');

        //no routing
        /*  if (!selected) {
              if (props.from && props.from === 'map') { } else {
                  if (props.user.modules.offices.length > 0) {
                      if (!props.from) {

                          if (props.user.modules.my_office) {
                              if (window.innerWidth > 1000)
                                  select('offices', props.user.modules.my_office, 'offices', 'menu')
                              else
                                  props.setPathlistValue(null)
                              if (window.innerWidth > 1000) {
                                  select('useful_info', props.user.modules.my_office, 'useful_info', 'menu')
                              }
                              setDefaultOffice(props.user.modules.my_office)
                          }

                          setOtherLocationOffices(props.user.modules.offices)
                          props.user.modules.offices.map((o, ind) => {
                              if (o.is_default_region) {
                                  setExpanded('panel' + ind)
                                  setSelectedOtherLocation(o.region)
                                  setSelectedRegionIndex(ind)
                                  setSelectedRegion(o.locations)
                              }
                          })
                      } else if (props.sel_office && !props.sel_office.is_default) {
                          setOtherLocationsView(true)
                      }
                  }

                  if (!props.user.modules.my_office && props.user.active_modules >= 1 && !selected) {
                      if (props.user.modules.bookshelf)
                          select('Bookshelf', props.user.modules.bookshelf, 'bookshelf_singlem', 'menu')
                      else if (props.user.modules.desk_booking)
                          select('desk_booking', props.user.modules.desk_booking, 'desk_booking', 'menu')
                      else if (props.user.modules.wellness_lab)
                          select('wellness', props.user.modules.wellness_lab, 'wellness', 'menu')
                      else if (props.user.modules.ready_to_grow)
                          select('ready_to_grow', props.user.modules.ready_to_grow, 'ready_to_grow_singlem', 'menu')
                      else if (props.user.modules.offices.length > 0) {
                          select('offices', props.user.modules.offices[0], 'offices', 'menu')
                          setOtherLocationsView(true)
                      }
                  }
              }
          }*/
        if (props.user.modules.custom_pages)
            setCustomPages(props.user.modules.custom_pages)
        setHidden(false)
    }, [props.reload])

    useEffect(() => {
        setHidden(props.hiddenHomepage)
    }, [props.hiddenHomepage])

    useEffect(() => {
        props.setIsOtherLocation(otherLocationsView)
        // per aprire l'accordion con il default region a true
        if (otherLocationsView && otherLocationOffices) {
            otherLocationOffices.map((o, index) => {
                if (o.is_default_region) {
                    setExpanded('panel' + index);
                }
            })
        } else {
            setExpanded(false);
        }
        if (document.getElementsByClassName('other_location_menu_cont') && document.getElementsByClassName('other_location_menu_cont')[0])
            document.getElementsByClassName('other_location_menu_cont')[0].scrollTo(0, 0)
    }, [otherLocationsView])

    const addInQueue = (data) => {
        let smm = subMenuMap
        smm.push({ type: 'page', value: data })
        setSubMenuMap([...smm])
        props.setWebPathlist([...smm])
    }

    const createSubMenu = (data) => {
        let sub = []
        data.menu.map((m) => {
            m.name = m.text
            sub.push(m)
        })
        setSubMenu(sub)
    }

    const select = (menu, o, index, from) => {
        //page button
        if (menu === 'page') {
            if (!o.page_id && o.id)
                o['page_id'] = o.id
            ds.getPagebyId(o.page_id).then((data) => {
                if (data.name)
                    props.setPathlistValue(data.name)

                createSubMenu(data)

                //active selecting from menu
                if (from === 'menu')
                    addInQueue(data)

                //passive selecting from back button
                // else if (from === 'back')
                //setDefaultOfficeView(false)

            })
            props.select(index, o)
        }
        //download file button
        else if (menu === 'file_url') {
            props.select(index, o)
        }
        //download file button
        else if (menu === 'wellness_file_url') {
            props.select(index, o)
        }
        // opportunities_feedback_form
        else if (menu === 'opportunities_feedback_form') {

            props.setHidden(true)
            props.select(index, o)
        }
        //dynamic page button
        else if (menu === 'page_id') {
            ds.getPagebyId(o).then((data) => {
                if (data.name)
                    props.setPathlistValue(data.name)

                createSubMenu(data)
                if (from === 'menu')
                    addInQueue(data)

            })
            props.select(index, o)
        }
        //custom page without page id, to update submenu
        else if (menu === 'custom_page') {
            createSubMenu(o)

            if (from === 'menu')
                addInQueue(o)

            setDefaultOfficeView(true)
            setSelectedKey(o.name)
            props.select(index, o)
        }
        else {
            //event page dynamic content
            if (menu === 'custom_pages_event') {
                setSubMenuMap([])
                setSubMenu([])
                props.setWebPathlist([{ type: 'event', value: o.name }])
                setOtherLocationsView(false)
                setDefaultOfficeView(false)
                props.select('custom_pages_event', o)
            }
            //dynamic page with page_id
            if (o.page_id)
                ds.getPagebyId(o.page_id).then((data) => {
                    if (data.menu) {
                        createSubMenu(data)

                        if (from === 'menu')
                            addInQueue(data)


                        setDefaultOfficeView(true)
                        setSelectedKey(o.name)
                        props.select('custom_pages', o)
                        if (window.innerWidth < 1000) {
                            setHidden(false)
                            props.setHidden(false)
                        }
                    }
                })

            if (o.name) {
                setSelectedKey(o.name)
                props.setPathlistValue(o.name)
            }
            else if (props.pathlist_value !== 'Wellness Lab') {
                setSelectedKey('')
                props.setPathlistValue('')
            }
            let toReturn = index;

            if (window.innerWidth < 1000 && menu !== 'offices') {
                setHidden(true)
                props.setHidden(true)
            }
            props.select(toReturn, o)
        }
        setTimeout(() => {
            if (document.getElementsByClassName('sidemenu_mobile') && document.getElementsByClassName('sidemenu_mobile')[0])
                document.getElementsByClassName('sidemenu_mobile')[0].scrollTo(0, 0, 'smooth')
        }, 200);
    }

    const back = () => {
        //ho gia rimosso il default content

        if (defaultOfficeView || otherLocationsView) {
            props.setTutorial(false)
        }

        if (props.profileShow)
            props.closeProfile()
        else {
            if (!hidden || hidden && window.innerWidth > 1000) {
                setHidden(false)
                props.setHidden(false)
                let sm = subMenuMap
                //back with a previous page id
                if (sm.length > 0 && sm[sm.length - 1].value && sm[sm.length - 1].value.previous_page_id) {
                    let index = sm.length - 1
                    let id = sm[index].value.previous_page_id
                    sm.splice(index, 1)
                    setSubMenuMap(sm)
                    props.setWebPathlist(sm)
                    select('page_id', id, 'custom_pages', 'back')

                } else {
                    let i = sm.length
                    sm.splice(sm.length - 1, 1)
                    let ind = sm.length - 1
                    //devo tornare al primo menu
                    if (sm.length == 0) {

                        setDefaultOfficeView(false)
                        //torno al primo menu di other location(accordions)
                        if (i > 0 && otherLocationsView) {
                            if (window.innerWidth > 1000)
                                select('useful_info', defaultOtherLocation, 'useful_info', 'menu')
                            setOtherLocationsView(true)
                            //setExpanded(false)
                        }
                        //torno sulla mia sede
                        else {
                            setOtherLocationsView(false)
                            if (window.innerWidth > 1000)
                                select('useful_info', defaultOffice, 'useful_info', 'menu')
                        }
                        setSubMenuMap([])
                        props.setWebPathlist([])
                        setSubMenu([])
                        props.setPathlistValue(null)
                    }

                    //ho già un previous id
                    else if (sm[ind].value.previous_page_id) {
                        select('page_id', sm[ind].value.previous_page_id, 'custom_pages', 'back')
                        setSubMenuMap(sm)
                        props.setWebPathlist(sm)

                    }
                    else if (sm.length > 0) {
                        let s = subMenuMap
                        let ind = s.length - 1

                        let lastMenu = s[ind]
                        if (lastMenu.type === 'wellness_orario_corsi' || lastMenu.type === 'wellness_my_reservations' || lastMenu.type === 'wellness_info')
                            props.setPathlistValue('Wellness Lab')

                        if (lastMenu.type === 'page' || lastMenu.type === 'page_id')
                            select('page', lastMenu.value, 'custom_pages', 'back')
                        else if (lastMenu.type === 'custom_page')
                            setDefaultOfficeView(false)
                        else if (lastMenu.type === 'default_offices')
                            openDefaultView('back')
                        else if (lastMenu.type === 'other_offices') {
                            openOtherLocationView('back', lastMenu.value)
                            props.setPathlistValue(getTranslationByKey(lastMenu.value.name))
                        }

                        setSubMenuMap(sm)
                        props.setWebPathlist(sm)
                    }
                }
            } else {
                let s = subMenuMap
                let ind = s.length - 1
                let lastMenu = s[ind]
                setHidden(false)
                props.setHidden(false)
                //wellness custom menu
                if (lastMenu && lastMenu.length > 0 && lastMenu[0].text === 'wellness_my_reservations')
                    props.setPathlistValue('Wellness Lab')
                //this
                else if (lastMenu && lastMenu.value && lastMenu.value.name)
                    props.setPathlistValue(lastMenu.value.name)
                else {
                    props.setPathlistValue(null)
                }

            }
        }
        // setDefaultOfficeView(false)
        //setOtherLocationsView(false)
    }
    //open my office menu and select useful info
    const openDefaultView = (from) => {
        setHidden(false)
        props.setHidden(false)
        select('offices', defaultOffice, 'offices', from)
        setDefaultOfficeView(true)
        setOtherLocationsView(false)
        let ds = new DeskService()
        //ds.getInfoLocationv2(defaultOffice.id).then((data) => {
        ds.getInfoLocationv4(defaultOffice.id).then((data) => {
            if (window.innerWidth > 1000) {
                select('useful_info', defaultOffice, 'useful_info', 'menu')
                setSelectedKey('useful_info')
                props.setPathlistValue('useful_info')
            }
            setSubMenu(data.additional_menu)
            if (from === 'menu') {
                let smm = subMenuMap
                smm.push({ type: 'default_offices', value: defaultOffice })
                setSubMenuMap(smm)
                props.setWebPathlist([...smm])
            }
        })
    }
    //open my office menu and select the module from path
    const openDefaultViewModuleSelect = (from, defaultOffice, module) => {
        setHidden(false)
        props.setHidden(false)
        select('offices', defaultOffice, 'offices', from, null)
        setDefaultOfficeView(true)
        setOtherLocationsView(false)
        let ds = new DeskService()
        //ds.getInfoLocationv2(defaultOffice.id).then((data) => {
        ds.getInfoLocationv4(defaultOffice.id).then((data) => {
            setSubMenu(data.additional_menu)
            data.additional_menu.map((m) => {
                if (m.text === module) {
                    chooseSelect(m)
                }
            })
            if (from === 'menu') {
                let smm = subMenuMap
                smm.push({ type: 'default_offices', value: defaultOffice })
                setSubMenuMap(smm)
                props.setWebPathlist([...smm])
            }
        })
    }
    //open other location menu
    const openOtherLocationViewModuleSelect = (from, lastMenu, module) => {
        setHidden(false)
        props.setHidden(false)
        select('offices', lastMenu, 'offices', from, null)
        setOtherLocationsView(true)
        let ds = new DeskService()
        let exists = false
        //ds.getInfoLocationv2(lastMenu.id).then((data) => {
        ds.getInfoLocationv4(lastMenu.id).then((data) => {
            setSubMenu(data.additional_menu)

            data.additional_menu.map((m) => {
                if (m.text === module) {
                    chooseSelect(m)
                    exists = true
                    setDefaultOfficeView(true)
                }
            })
            if (!exists) {
                setTimeout(() => {

                    UseAlertMessage(400, 'Module not available')
                }, 200);
            }

            if (from === 'menu') {
                let smm = subMenuMap
                smm.push({ type: 'other_offices', value: lastMenu })
                setSubMenuMap(smm)
                props.setWebPathlist([...smm])
            }

        })

    }
    //open other location menu
    const openOtherLocationView = (from, lastMenu) => {
        setHidden(false)
        props.setHidden(false)

        setOtherLocationsView(true)
        let ds = new DeskService()
        //ds.getInfoLocationv2(lastMenu.id).then((data) => {
        ds.getInfoLocationv4(lastMenu.id).then((data) => {
            if (window.innerWidth > 1000) {
                select('useful_info', lastMenu, 'useful_info', 'menu')
                setSelectedKey('useful_info')
                props.setPathlistValue('useful_info')
            }
            setSubMenu(data.additional_menu)
            if (from === 'menu') {
                let smm = subMenuMap
                smm.push({ type: 'default_offices', value: defaultOffice })
                setSubMenuMap(smm)
                props.setWebPathlist([...smm])
            }
        })
    }
    //open other location menu and select useful info
    const openLocationView = (loc, from) => {
        select('offices', loc, 'offices', from)
        setDefaultOtherLocation(loc)
        setDefaultOfficeView(true)
        let ds = new DeskService()
        //ds.getInfoLocationv2(loc.id).then((data) => {
        ds.getInfoLocationv4(loc.id).then((data) => {
            setSubMenu(data.additional_menu)
            if (window.innerWidth > 1000) {
                select('useful_info', loc, 'useful_info', 'menu')
                setSelectedKey('useful_info')
                props.setPathlistValue('useful_info')
            }
            if (from === 'menu') {
                let smm = subMenuMap
                smm.push({ type: 'other_offices', value: loc })
                setSubMenuMap(smm)
                props.setWebPathlist([...smm])
            }
        })

    }

    const chooseSelect = (m) => {
        //da mettere solo quando è una final page
        if ((window.innerWidth < 1000 && m.type === 'page' && !m.is_menu_page && m.page_id)) {
            setHidden(true)
            props.setHidden(true)
        }
        if (m.type === 'page' && m.is_menu_page && m.page_id)
            select('page', m, 'custom_pages', 'menu')
        else if (m.type === 'page' && !m.is_menu_page && m.page_id)
            select('final_page', m, 'final_page', 'menu')
        else if (m.type === 'page' && !m.is_menu_page && m.file_url)
            select('file_url', m.file_url, 'file_url', 'menu')
        else if (m.type === 'page' && !m.is_menu_page && m.external_url)
            window.open(m.external_url, "_blank")
        else if (m.type === 'module')
            //custom wellness menu
            if (m.text === 'wellness_lab' && m.active) {
                let smm = subMenuMap
                let sm = [
                    { type: 'wellness_custom_menu', name: getTranslationByKey('WELLNESS.DASHBOARD.RESERVATIONS'), text: 'wellness_my_reservations', module: m },
                    { type: 'wellness_custom_menu', name: getTranslationByKey('WELLNESS.DASHBOARD.TIMETABLE'), text: 'wellness_orario_corsi', module: m },
                    { type: 'wellness_custom_menu', name: getTranslationByKey('WELLNESS.INFO.TITLE'), text: 'wellness_info', module: m },
                    { type: 'wellness_custom_menu', name: getTranslationByKey('WELLNESS.TUTORIAL.PAGE2.ATTENDANCEPROCEDURE'), text: 'wellness_attendance_procedure', module: m },
                    { type: 'wellness_custom_menu', name: getTranslationByKey('WELLNESS.TUTORIAL.PAGE2.REGULATIONS'), text: 'wellness_regulations', module: m },
                ]
                smm.push(sm)
                setSubMenu(sm)
                setSubMenuMap(smm)
                props.setWebPathlist([...smm])
                if (window.innerWidth > 1000) {
                    props.setPathlistValue('wellness_orario_corsi')
                    setSelectedKey('wellness_orario_corsi')
                    select('wellness_orario_corsi', sm[1], 'custom_module', 'menu')
                } else
                    props.setPathlistValue('Wellness Lab')
            }
            else if (m.text === 'wellness_lab' && !m.active) {
                let custom_menu = { type: 'wellness_custom_menu', name: getTranslationByKey('WELLNESS.DASHBOARD.RESERVATIONS'), text: 'wellness_lab_not_active', module: m }
                select('wellness_lab_not_active', custom_menu, 'custom_module', 'menu')
            }
            else {
                setHidden(true)
                props.setHidden(true)
                select('custom_module', m, 'custom_module', 'menu')
            }
        //custom wellness download docs
        else if (m.type === 'wellness_custom_menu' && (m.text === "wellness_regulations" || m.text === 'wellness_attendance_procedure')) {
            if (m.text === "wellness_regulations") {
                //ws.downloadPdf('RegulationOfUse')
                select('wellness_file_url', 'RegulationOfUse', 'wellness_file_url', 'menu')
            }
            else if (m.text === 'wellness_attendance_procedure')
                //ws.downloadPdf('AttendancesManagementProcedure')
                select('wellness_file_url', 'AttendancesManagementProcedure', 'wellness_file_url', 'menu')
        }
        else if (m.type === 'useful_info') {
            setHidden(true)
            props.setHidden(true)
            select('useful_info', m, 'useful_info', 'menu')
        }
        else {
            if (window.innerWidth < 10000) {
                setHidden(true)
                props.setHidden(true)
            }
            select(m.type, m, 'custom_module', 'menu')
        }

    }
    //expand accordion function
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    //my office menu
    let default_office = ''
    if (defaultOffice)
        default_office = <div className=" menu_row offices_menu" /*Style={'background-color:#' + o.color}*/ onClick={() => openDefaultView('menu')} >
            <div className="logo_menu">
                <div className="offices_logo" Style={'background-color:#' + props.user.button_image_color + ';'} >
                    <div className="logo_offices_icon" Style={'background-image: url("' + defaultOffice.logo + '")'}></div>
                </div>
            </div>

            <div className="text_menu_container">
                <div className={selectedKey === defaultOffice.name && window.innerWidth > 1000 ? "text_menu desk_menu menu_selected" : "text_menu desk_menu"} > {getTranslationByKey(defaultOffice.name)}</div>
            </div>
        </div>

    //other location accordions menu
    let otherLocationsMenu = ''
    if (otherLocationOffices)
        otherLocationsMenu = otherLocationOffices.map((o, ind) =>
            <Accordion className="other_loc_accordions" expanded={expanded === 'panel' + ind} onChange={handleChange('panel' + ind)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="small" />}
                    aria-controls={"panel" + ind + "a-content"}
                    id={"panel" + ind + "a-header"}
                    className={" menu_row offices_menu other_loc_accordion"}
                >
                    <Typography>
                        <div className="notificationText">{getTranslationByKey(o.region)}</div>
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <div className="accordion_details_container">
                        {o.locations.map((of) =>
                            <div className=" menu_row1 offices_menu" onClick={() => openLocationView(of, 'menu')}>
                                <div className="logo_menu_other_locations1">
                                    <div className="offices_logo1" Style={'background-color:#' + (of.logo ? props.user.button_image_color : 'white') + ';'} >
                                        <div className="logo_offices_icon1" Style={'background-image: url("' + of.logo + '")'}></div>
                                    </div>
                                </div>

                                <div className="text_menu_container_other_locations1">
                                    <div className={selectedKey === of.name && window.innerWidth > 1000 ? "text_menu1 desk_menu menu_selected" : "text_menu1 desk_menu"} > {getTranslationByKey(of.name)}</div>
                                </div>
                            </div>)}
                    </div>
                </AccordionDetails>
            </Accordion>)

    const handleSelectedRegion = (region, ind) => {
        setSelectedRegion(region.locations)
        setSelectedRegionIndex(ind)
    }

    let otherLocationsRegions = ''
    if (otherLocationOffices) {
        otherLocationsRegions =
            <div Style="margin-top: 10px;">
                <ScrollContainer>
                    <div className="flex">
                        {otherLocationOffices.map((o, ind) =>
                            <div className={selectedRegionIndex == ind ?
                                "other_locations_region_button other_locations_region_button_selected" : "other_locations_region_button pointer"}
                                onClick={() => handleSelectedRegion(o, ind)}>
                                {getTranslationByKey(o.region)}
                            </div>
                        )}</div>
                </ScrollContainer>
            </div>
    }

    let otherLocationsItem = ''
    if (otherLocationOffices && selectedRegion && selectedRegion.length > 0) {
        otherLocationsItem = selectedRegion.map((of) =>
            <div className=" menu_row1 offices_menu" onClick={() => openLocationView(of, 'menu')}>
                <div className="logo_menu_other_locations1">
                    <div className="offices_logo1" Style={'background-color:#' + (of.logo ? props.user.button_image_color : 'white') + ';'} >
                        <div className="logo_offices_icon1" Style={'background-image: url("' + of.logo + '")'}></div>
                    </div>
                </div>

                <div className="text_menu_container_other_locations1">
                    <div className={selectedKey === of.name && window.innerWidth > 1000 ? "text_menu1 desk_menu menu_selected" : "text_menu1 desk_menu"} > {getTranslationByKey(of.name)}</div>
                </div>
            </div>)
    }

    //other locations
    let otherLocation = ''
    if (otherLocationOffices && otherLocationOffices.length > 0)
        otherLocation = <CustomMenuButton
            class={'other_locations_menu'}
            logo={'other_locations_icon'}
            handleClick={setOtherLocationsView}
            click_value={true}
            color={props.user.button_image_color}
            title={props.user.modules.my_office ? 'DESKBOOKING.OTHERLOCATIONS.TITLE' : 'MENU.OFFICELOCATION.TITLE'}
        />
    //custom pages menu
    let custom_pages = ''
    if (customPages)
        custom_pages = customPages.map((cp) =>
            <IconMenuButton
                class={'offices_menu'}
                logo={'logo_offices_icon'}
                logo_url={cp.logo}
                selectedKey={selectedKey}
                key={cp.name}
                handleClick={select}
                module={cp}
                moduleName={'custom_page'}
                index={'custom'}
                from={'menu'}
                color={props.user.button_image_color}
                external_url={cp.external_url} />
        )

    //custom submenu
    let default_office_menu = ''
    if (subMenu && subMenu.length > 0)
        default_office_menu = subMenu.map((m) =>
            <div className=" no_logo_menu pointer" onClick={() => chooseSelect(m)}>
                <div className="no_logo_menu_container">
                    <div className={selectedKey === m.name || selectedKey === m.text && window.innerWidth > 1000 ? "no_logo_text_menu  menu_selected" : "no_logo_text_menu "}> {getTranslationByKey(m.name)}</div>
                </div>
            </div>)

    return (
        <React.Fragment>

            {/*back buttton*/}
            {(hidden) || subMenuMap.length > 0 || selectedKey === 'offices' || otherLocationsView || subMenu.length > 0 ?
                <BackButton id='sidebar' backButtonFunction={() => back()} index={202} /> : ''}

            {/*sidebar*/}
            <div className={"leftbar"} Style={hidden && window.innerWidth < 1000 ? 'display:none' : ''}>

                {/*event banner*/}
                {/*event visibile in tutti i menu solo lato web, lato mobile va mostrato solo in homepage*/}
                {props.user.events && props.user.events.length > 0 ?
                    !otherLocationsView && !defaultOfficeView ?
                        <EventCountdownLeftbar select={select} user={props.user} event={props.user.event} events={props.user.events} /> : <div Style={"height: 10px;"}></div>
                    : ''}

                {/*welcome text when there isn't an event*/}
                {props.user.events && props.user.events.length > 0 ?
                    <div></div>
                    :
                    (!props.pathlist_value && !otherLocationsView ?
                        <div className="welcome_string">{selectedKey ? getTranslationByKey('MENU.HOMEVIEW.WELCOME') : ''}
                        </div> : '')}


                <div className={(props.user.events && props.user.events.length > 0 ? "" : (defaultOfficeView ? 'sidemenu_no_event submenu_no_event sidemenu_mobile' : "sidemenu_no_event sidemenu_mobile")) + (otherLocationsView && !defaultOfficeView ? "other_location_menu_cont sidemenu_mobile" : (!props.pathlist_value ? "sidemenu_cont sidemenu_mobile" : "sidemenu_cont side_submenu_cont_mobile sidemenu_mobile"))} >
                    {defaultOfficeView ?
                        default_office_menu
                        :
                        !otherLocationsView ?
                            <React.Fragment>
                                {/*bookshelf*/}
                                {props.user.modules && props.user.modules.bookshelf ?
                                    <IconMenuButton
                                        class={'offices_menu'}
                                        logo={'logo_bookshelf_icon'}
                                        selectedKey={selectedKey}
                                        key='admin_bookshelf'
                                        handleClick={select}
                                        module={props.user.modules.bookshelf}
                                        moduleName={'Bookshelf'}
                                        index={'bookshelf_singlem'}
                                        from={'menu'}
                                        color={props.user.button_image_color} />
                                    : ''}
                                {/*desk booking*/}
                                {props.user.modules && props.user.modules.desk_booking ?
                                    <IconMenuButton
                                        class={'offices_menu'}
                                        logo={'logo_desk_booking_icon'}
                                        selectedKey={selectedKey}
                                        key='desk_booking'
                                        handleClick={select}
                                        module={props.user.modules.desk_booking}
                                        moduleName={'desk_boooking'}
                                        index={'desk_booking'}
                                        from={'menu'}
                                        color={props.user.button_image_color} />

                                    : ''}
                                {/* wellness lab signle module admin/trainer */}
                                {props.user.modules && props.user.modules.wellness_lab && (props.user.modules.wellness_lab.is_admin || props.user.modules.wellness_lab.is_trainer) ?
                                    <IconMenuButton
                                        class={'wellness_lab_menu'}
                                        logo={'logo_wellness_lab_icon'}
                                        logo_url={props.user.modules.wellness_lab.logo}
                                        selectedKey={selectedKey}
                                        key='admin_wellness'
                                        handleClick={select}
                                        module={props.user.modules.wellness_lab}
                                        moduleName={'wellness'}
                                        index={'wellness'}
                                        from={'menu'}
                                        color={props.user.button_image_color} />

                                    : ''}
                                {/*ready to grow  single module admin*/}
                                {props.user.modules && props.user.modules.ready_to_grow ?
                                    <IconMenuButton
                                        class={'ready_to_grow_menu'}
                                        logo={'logo_ready_to_grow_icon'}
                                        logo_url={props.user.modules.ready_to_grow.logo}
                                        selectedKey={selectedKey}
                                        key='admin_ready_to_grow'
                                        handleClick={select}
                                        module={props.user.modules.ready_to_grow}
                                        moduleName={'ready_to_grow'}
                                        index={'ready_to_grow_singlem'}
                                        from={'menu'}
                                        color={props.user.button_image_color} />
                                    : ''}

                                {props.user.modules && props.user.modules.feed ?
                                    <>
                                        <IconMenuButton
                                            class={'feed_menu offices_menu_web'}
                                            logo={'logo_feed_icon'}
                                            logo_url={props.user.modules.feed.logo}
                                            selectedKey={selectedKey}
                                            key='admin_feed'
                                            handleClick={select}
                                            module={props.user.modules.feed}
                                            moduleName={'feed'}
                                            index={'feed'}
                                            from={'menu'}
                                            color={props.user.button_image_color} />
                                    </>
                                    : ''}
                                {default_office}
                                {otherLocation}
                                {props.user.modules && props.user.modules.opportunities ?
                                    <>
                                        <IconMenuButton
                                            class={'ready_to_grow_menu offices_menu_web'}
                                            logo={'logo_ready_to_grow_icon'}
                                            logo_url={props.user.modules.opportunities.logo}
                                            selectedKey={selectedKey}
                                            key='opportunities'
                                            handleClick={select}
                                            module={props.user.modules.opportunities}
                                            moduleName={'opportunities'}
                                            index={'opportunities'}
                                            from={'menu'}
                                            color={props.user.button_image_color} />

                                    </>
                                    : ''}
                                {props.user.modules && props.user.modules.share2go ?
                                    <>
                                        <IconMenuButton
                                            class={'ready_to_grow_menu offices_menu_web'}
                                            logo={'logo_ready_to_grow_icon'}
                                            logo_url={props.user.modules.share2go.logo}
                                            selectedKey={selectedKey}
                                            key='admin_share_to_go'
                                            handleClick={select}
                                            module={props.user.modules.share2go}
                                            moduleName={'share_to_go'}
                                            index={'share_to_go'}
                                            from={'menu'}
                                            color={props.user.button_image_color} />
                                    </>
                                    : ''}
                                {custom_pages}
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {otherLocationsRegions}
                                {otherLocationsItem}
                            </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}