import React, { useEffect, useState } from "react"
import Select from "react-select";
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import { customStyles, customStylesMobileFeedbackForm, customStylesShareToGo, getTranslationByKey } from '../global';
import { UseAlertMessage } from "./Alert";
import { ShareToGoService } from "../Services/Services";
import { LoadingComponentWeb } from "./LoadingComponent";
import Modal from 'react-modal';
import Markdown from 'markdown-to-jsx'

export function ShareToGoForm(props) {

    const S2GService = new ShareToGoService
    const [parks, setParks] = React.useState([])
    const [selectedPark, setSelectedPark] = React.useState('')
    const [selectedParkLabel, setSelectedParkLabel] = React.useState('')
    const [excludedDates, setExcludedDates] = React.useState([])
    const [selectedDateFrom, setSelectedDateFrom] = React.useState('')
    const [selectedDateTo, setSelectedDateTo] = React.useState('')
    const [date, setDate] = useState(new Date())
    const [isLoading, setLoading] = useState(true)
    const [isLoadingSlots, setLoadingSlots] = useState(false)
    const [ride, setRide] = useState([])
    const [isRideBooked, setIsRideBooked] = useState(false)
    const [openBookModal, setOpenBookModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [calendarDate, setCalendarDate] = useState(new Date().toISOString().split('T')[0])
    const [dateString, setDateString] = useState('')
    const [differenceString, setDifferenceString] = useState('TODAY')
    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')
    const [bookedDates, setBookedDates] = useState([])
    const [labelFrom, setLabelFrom] = useState('')
    const [labelTo, setLabelTo] = useState('')

    useEffect(() => {
        let tmp = []
        let startDate = ''
        let endDate = ''
        if (props.share2GoOptions) {
            if (props.share2GoOptions.car_parks) {
                props.share2GoOptions.car_parks.map((cp) => {
                    tmp.push({ label: cp.name, value: cp.id })
                })
                setParks([...tmp])
            }
            if (props.share2GoOptions.default_car_park) {
                setSelectedPark({
                    label: props.share2GoOptions.default_car_park.name,
                    value: props.share2GoOptions.default_car_park.id
                })
                setSelectedParkLabel(props.share2GoOptions.default_car_park.name)
            }
            setExcludedDates(props.share2GoOptions.excluded_dates)
            setDate(props.share2GoOptions.min_request_date)
            setCalendarDate(props.share2GoOptions.min_request_date)
            setMinDate(props.share2GoOptions.min_request_date)
            setMaxDate(props.share2GoOptions.max_request_date)
            startDate = formatData(props.share2GoOptions.min_request_date)
            endDate = formatData(props.share2GoOptions.max_request_date)
            S2GService.getRideDates(startDate, endDate).then((d) => {
                setBookedDates(d)
                S2GService.getByDate(props.share2GoOptions.min_request_date, props.share2GoOptions.default_car_park.id).then((data) => {
                    setLabelFrom(formatLabelData(data.from_date))
                    setLabelTo(formatLabelData(data.to_date))
                    if (data.ride) {
                        setIsRideBooked(true)
                        setRide(data.ride)
                        setSelectedParkLabel(data.car_park.name)
                    }
                    setLoading(false)
                }).catch(function () {
                    setLoading(false)
                });
            }).catch(function () {
                setLoading(false)
            });
        }
    }, [])

    const formatLabelData = (date) => {
        let new_date = new Date(date)
        let bodyDate = ""
        bodyDate = ("0" + new_date.getDate()).slice(-2).toString() + '/' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '/' + new_date.getFullYear().toString()
        return bodyDate
    }


    useEffect(() => {
        setLoading(true)
        setIsRideBooked(false)
        let tmp = ''
        if (selectedPark.value)
            S2GService.getS2GOptionsByID(selectedPark.value).then((d) => {
                setDate(d.min_request_date)
                tmp = d.min_request_date
                S2GService.getExcludedDatesByPark(selectedPark.value).then((data2) => {
                    setExcludedDates(data2.excluded_dates)
                    S2GService.getByDate(d.min_request_date, selectedPark.value).then((data) => {
                        setLabelFrom(formatLabelData(data.from_date))
                        setLabelTo(formatLabelData(data.to_date))
                        if (data.ride) {
                            setIsRideBooked(true)
                            setRide(data.ride)
                            setSelectedParkLabel(data.car_park.name)
                        }
                        setLoading(false)
                    }).catch(function (error) {
                        setLoading(false)
                    });

                }).catch(function () {
                    setLoading(false)
                });


            }).catch(function () {
                setLoading(false)
            });

    }, [selectedPark])

    const formatData = (date) => {
        let new_date = new Date(date)
        let bodyDate = ""
        bodyDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
        return bodyDate
    }

    const renderDayCell = (cell) => {
        excludedDates.map((ed) => {
            if (getData(cell.date) == getData(ed)) {
                cell.isDisabled = true
            }
        })

        let day = cell.date.getDate();
        let month = parseInt(cell.date.getMonth() + 1)
        if (month < 10) {
            month = '0' + month
        }
        if (day < 10) {
            day = '0' + day
        }
        let newDate = cell.date.getFullYear() + '-' + month + '-' + day
        if (bookedDates)
            bookedDates.map((d) => {
                if (newDate == formatData(d)) {
                    cell.element.children[0].classList.add("a-booked")
                }
            })
    }


    const datediff = (first, second) => {
        return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
    }

    const setCalendarDateFunction = (val, dateStr, newDifferenceString) => {
        let d = new Date()
        d.setFullYear(val.split('-')[0])
        d.setMonth(parseInt(val.split('-')[1], '') - 1)
        d.setDate(val.split('-')[2])

        let newDate = d.toISOString().split('T')[0]
        let finalDate = newDate.split('-')[0] + '-' + newDate.split('-')[1] + '-' + (newDate.split('-')[2])

        setCalendarDate(finalDate)
        setDateString(dateStr)
        setDifferenceString(newDifferenceString)
    }

    const dateChange = (selectedDate) => {
        setIsRideBooked(false)
        setLoading(true)
        let today = new Date();
        let newDate = ''
        let differenceDays = datediff(today, selectedDate.value);
        let newDateString = "";
        let newDifferenceString = "";

        newDate = selectedDate.value.getFullYear().toString() + "-" + (selectedDate.value.getMonth() + 1).toString() + "-" + selectedDate.value.getDate().toString();
        setDate(new Date(selectedDate.value).toISOString().split('T')[0])

        S2GService.getByDate(newDate, selectedPark.value).then((data) => {
            setLabelFrom(formatLabelData(data.from_date))
            setLabelTo(formatLabelData(data.to_date))
            if (data.ride) {
                setIsRideBooked(true)
                setRide(data.ride)
                setSelectedParkLabel(data.car_park.name)
            }
            setLoading(false)
        }).catch(function (error) {
            setLoading(false)
        });

        let lang = navigator.language
        if (localStorage.getItem("selectedLanguage"))
            lang = localStorage.getItem("selectedLanguage")

        if (differenceDays == null) {
            newDateString = selectedDate.value.toLocaleDateString(lang.split('-')[0], { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
        } else if (differenceDays === 0) {
            newDateString = "TODAY";
            newDifferenceString = "TODAY";
        } else if (differenceDays === 1) {
            newDateString = "TOMORROW";
            newDifferenceString = "TOMORROW";
        } else if (differenceDays === -1) {
            newDateString = "YESTERDAY";
            newDifferenceString = "YESTERDAY";
        } else if (differenceDays > 0) {
            newDateString = selectedDate.value.toLocaleDateString(lang.split('-')[0], { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = differenceDays + " DAYS FROM TODAY";
        } else {
            newDateString = selectedDate.value.toLocaleDateString(lang.split('-')[0], { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = Math.abs(differenceDays) + " DAYS AGO";
        }

        setCalendarDateFunction(newDate, newDateString, newDifferenceString)
        setDateString(newDateString)
        setDifferenceString(newDifferenceString)
        setDate(selectedDate.value)
    };

    let lang = navigator.language
    if (localStorage.getItem("selectedLanguage"))
        lang = localStorage.getItem("selectedLanguage")

    let date_title = new Date(date)
    let day = date_title.toLocaleDateString(lang.split('-')[0], { weekday: 'long' })
    let dtitle = day + ', ' + ("0" + date_title.getDate()).slice(-2).toString() + ' ' + date_title.toLocaleDateString(lang, { month: 'long' }).replace(',', '') //+ ' ' + date_title.getFullYear().toString()

    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = ("0" + new_date.getDate()).slice(-2).toString() + '/' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '/' + new_date.getFullYear().toString()
        return toStringDate
    }

    // Classic Mon to Fri
    function getNextWorkDay(date) {
        let d = new Date(+date);
        let day = d.getDay() || 7;
        d.setDate(d.getDate() + (day > 4 ? 8 - day : 1));
        return d;
    }


    const bookRide = () => {
        setLoading(true)
        setOpenBookModal(false)
        let new_date = new Date(date)
        let bodyDate = ""
        bodyDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
        let body = ''
        if (selectedPark) {
            body = {
                "ride_date": bodyDate,
                "park_id": selectedPark.value
            }

            S2GService.insertRide(body).then((data) => {
                S2GService.getRideDates(formatData(minDate), formatData(maxDate)).then((d) => {
                    setBookedDates(d)
                    S2GService.getByDate(formatData(date), selectedPark.value).then((data) => {
                        setLabelFrom(formatLabelData(data.from_date))
                        setLabelTo(formatLabelData(data.to_date))
                        if (data.ride) {
                            setIsRideBooked(true)
                            setIsRideBooked(true)
                            setRide(data.ride)
                            setSelectedParkLabel(data.car_park.name)
                        }
                        setLoading(false)
                    }).catch(function () {
                        setLoading(false)
                    });
                }).catch(function () {
                    setLoading(false)
                });
            }).catch(function () {
                setLoading(false)
            });
        } else {
            UseAlertMessage(404, getTranslationByKey('SHARETOGO.BOOKING.INSERTCARPARK'))
        }
    }

    const deleteRide = () => {
        setOpenDeleteModal(false)
        setLoading(true)
        S2GService.deleteRide(ride.id).then(() => {
            let new_date = new Date(date)
            let bodyDate = ""
            bodyDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
            S2GService.getByDate(formatData(date), selectedPark.value).then((data) => {
                setLabelFrom(formatLabelData(data.from_date))
                setLabelTo(formatLabelData(data.to_date))
                if (data.ride) {
                    setIsRideBooked(true)
                    setRide(data.ride)
                    setSelectedParkLabel(data.car_park.name)
                } else {
                    setRide(data.ride)
                }
                S2GService.getRideDates(formatData(minDate), formatData(maxDate)).then((d) => {
                    setBookedDates(d)
                    setLoading(false)
                }).catch(function () {
                    setLoading(false)
                });
            }).catch(function () {
                setLoading(false)
            });
        }).catch(function () {
            setLoading(false)
        });

    }

    const handleSelectedPark = (e) => {
        setSelectedPark(e)
        setSelectedParkLabel(e.label)
    }

    let customConfirmModalStyles = {
        content: {
            top: window.innerWidth > 1000 ? '50%' : '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            "min-height": '220px',
            marginRight: '-50%',
            marginTop: '20px',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": window.innerWidth > 1000 ? "#fff" : "#f0efef"
        }
    };

    return (
        <div className="share_to_go_form_container share_to_go_form_container_mobile">
            {isLoading ?
                <LoadingComponentWeb />
                :
                <React.Fragment>

                    <div className="share_to_go_select_width">
                        <Select
                            value={selectedPark}
                            isClearable={false}
                            isSearchable={false}
                            inputProps={{ readOnly: true }}
                            onChange={(e) => handleSelectedPark(e)}
                            options={parks}
                            className="opportunities_select s2g_select"
                            styles={customStylesShareToGo}
                        />
                    </div>
                    <div className="share_to_go_form_line">
                        <div className="flex">
                            <div Style={"text-align: -webkit-center;"}>
                                <div className="Calendar  ready-to-grow-calendar share_to_go_calendar" >
                                    <CalendarComponent change={dateChange} firstDayOfWeek={1} renderDayCell={renderDayCell} min={minDate} max={maxDate} value={date} />
                                    <div className="share_to_go_message">
                                        <div>{getTranslationByKey('SHARETOGO.BOOKING.DISABLEDDAYS')}</div>
                                    </div>
                                </div>

                                <div Style={"display: flex;justify-content: center;"}>
                                    <div className="share_to_go_titleday">
                                        <div className="bold s2g_day_title" Style={"text-transform: uppercase;"}>{dtitle}</div>
                                        <div id="subtitle-ready-to-grow" className="s2g_day_subtitle">{differenceString}</div>
                                    </div>
                                </div>
                                <div className="share_to_go_book_slot_container">
                                    {isRideBooked && ride ?
                                        <div className="flex item-center">
                                            <div className="share_to_go_book_slot" Style={"text-align: -webkit-left;"}>
                                                <div className="slot_subtitle">{getTranslationByKey('SHARETOGO.BOOKING.FROM')} {formatLabelData(ride.start_date)} {getTranslationByKey('SHARETOGO.BOOKING.TO')} {formatLabelData(ride.end_date)}</div>
                                                <div className="bold">{ride.car_park ? ride.car_park.name : ''}</div>
                                            </div>
                                            <div Style={"margin-left: auto;"}>
                                                {ride.is_deletable ?
                                                    <div className="pointer remove_booking" onClick={() => setOpenDeleteModal(true)}>{getTranslationByKey('SHARETOGO.BOOKING.DELETE')}</div>
                                                    :
                                                    <div className="pointer remove_booking">{getTranslationByKey('SHARETOGO.BOOKING.BOOKED')}</div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="flex item-center">
                                            <div className="share_to_go_book_slot" Style={"text-align: -webkit-left;"}>
                                                <div className="slot_subtitle">{getTranslationByKey('SHARETOGO.BOOKING.FROM')} {labelFrom} {getTranslationByKey('SHARETOGO.BOOKING.TO')} {labelTo}</div>
                                                <div className="bold">{selectedParkLabel ? selectedParkLabel : ''}</div>
                                            </div>
                                            <div Style={"margin-left: auto;"}>
                                                <div className="pointer book_button" onClick={() => setOpenBookModal(true)}>{getTranslationByKey('SHARETOGO.BOOKING.BOOK')}</div>
                                            </div>
                                        </div>}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div>
                    </div>

                </React.Fragment>
            }
            <Modal
                isOpen={openDeleteModal}
                onAfterOpen={null}
                onRequestClose={() => setOpenDeleteModal(false)}
                style={customConfirmModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
                preventScroll={true}
            >
                <div className="close" >
                    <svg onClick={() => setOpenDeleteModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="modal_body">
                    <div Style="margin-top: 10px;">
                        <div className="bold">{getTranslationByKey('SHARETOGO.BOOKING.AREYOUSURE')}</div>
                        <br />
                        <div>
                            <div>{getTranslationByKey('SHARETOGO.BOOKING.FROM')}: <span className="bold">{getData(date)}</span> </div>
                            <div><span Style="text-transform: capitalize;">{getTranslationByKey('SHARETOGO.BOOKING.TO')}</span>: <span className="bold">{getData(getNextWorkDay(date))}</span></div>
                            <div>{getTranslationByKey('SHARETOGO.MYBOOKINGS.CARPARK')}: <span className="bold">{selectedParkLabel ? selectedParkLabel : ''}</span></div>
                            <div Style="margin-top: 10px;"><Markdown>{getTranslationByKey('SHARETOGO.BOOKING.CONFIRMDELETE')}</Markdown></div>
                        </div>
                    </div>
                    <div className="opportunities_action_button_container flex">
                        <div className="opportunities_action_white_button bold pointer flex" Style="margin-right: 10px;" onClick={() => setOpenDeleteModal(false)}>
                            <div className="bold">
                                {getTranslationByKey('OPPORTUNITIES.BUTTON.CANCEL')}
                            </div>
                        </div>
                        <div className="opportunities_action_button bold pointer flex" onClick={() => deleteRide()}>
                            <div className="bold">
                                {getTranslationByKey('OPPORTUNITIES.LABLE.CONFIRM')}
                            </div>
                        </div>
                    </div>
                </div>

            </Modal >
            <Modal
                isOpen={openBookModal}
                onAfterOpen={null}
                onRequestClose={() => setOpenBookModal(false)}
                style={customConfirmModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
                preventScroll={true}
            >
                <div className="close" >
                    <svg onClick={() => setOpenBookModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="modal_body">
                    <div Style="margin-top: 10px;">
                        <div className="bold">{getTranslationByKey('SHARETOGO.BOOKING.CONFIRMRESERVATION')}</div>
                        <br />
                        <div>

                            <div>{getTranslationByKey('SHARETOGO.BOOKING.FROM')}: <span className="bold">{labelFrom}</span> </div>
                            <div><span Style="text-transform: capitalize;">{getTranslationByKey('SHARETOGO.BOOKING.TO')}</span>: <span className="bold">{labelTo}</span></div>
                            <div>{getTranslationByKey('SHARETOGO.MYBOOKINGS.CARPARK')}: <span className="bold">{selectedParkLabel ? selectedParkLabel : ''}</span></div>
                        </div>
                    </div>
                    <div className="opportunities_action_button_container flex">
                        <div className="opportunities_action_white_button bold pointer flex" Style="margin-right: 10px;" onClick={() => setOpenBookModal(false)}>
                            <div className="bold">
                                {getTranslationByKey('OPPORTUNITIES.BUTTON.CANCEL')}
                            </div>
                        </div>
                        <div className="opportunities_action_button bold pointer flex" onClick={() => bookRide()}>
                            <div className="bold">
                                {getTranslationByKey('SHARETOGO.BUTTON.CONFIRM')}
                            </div>
                        </div>
                    </div>
                </div>

            </Modal >
        </div >
    )
}