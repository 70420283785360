import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import Select from "react-select";
import { OpportunitiesService } from "../Services/Services";
import { OpenQuestions, RadioQuestions } from "./OpportunitiesQuestions";
import { UseAlert, UseAlertMessage, UseMessage } from '../View/Alert';
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Form } from "react-bootstrap";
import { getTranslationByKey } from "../global";
import Markdown from 'markdown-to-jsx'

export function OpportunitiesDetailHeader(props) {

    const OS = new OpportunitiesService
    const [openBookModal, setOpenBookModal] = useState(false)
    const [openFormModal, setOpenFormModal] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [nextView, setNextView] = useState(false)
    const [slots, setSlots] = useState([])
    const [selectedSlot, setSelectedSlot] = useState([])
    const [answers, setAnswers] = useState([])

    useEffect(() => {
        let slotList = []
        if (props.detail.slots)
            props.detail.slots.map((s) => {
                if (s.is_slot_bookable)
                    slotList.push({ value: s.id, label: s.slot_dates })
            })
        setSlots(slotList)
    }, [])

    useEffect(() => {
        if (!openFormModal)
            window.scrollTo(0, 0, 'smooth')
    }, [openFormModal])

    useEffect(() => {
        if (!openBookModal) {
            if (props.detail && props.detail.initial_form && props.detail.initial_form.questions) {
                let form = props.detail.initial_form.questions
                form.map((elem) => {

                    elem.answer = ''
                    elem.answer_id = ''

                })

                setNextView(false)
                setAnswers([])
                setSelectedSlot([])
            }
        }
    }, [openBookModal])

    const bookOpportunity = () => {
        let tmp = []
        let ret = true
        if (props.detail.initial_form && props.detail.initial_form.questions && props.detail.initial_form.questions.length > 0) {
            if (answers.questions && answers.questions.length > 0)
                answers.questions.map((a) => {
                    if (a.answer)
                        tmp.push({ "question_id": a.id, "answer": a.answer })
                    else {
                        ret = false
                    }
                })
            else {

                ret = false
            }
        } else {
            if (!props.detail.is_full)
                if (selectedSlot && !selectedSlot.value)
                    ret = false
        }


        if (!ret) {
            return UseMessage(getTranslationByKey('OPPORTUNITIES.LABLE.WARNING.ALLFIELDSREQUIRED'))
        }
        let body = {
            "slot_id": selectedSlot.value, // number | undefined -> Required for experiences
            "form": {
                "form_id": props.detail.initial_form && props.detail.initial_form.questions && props.detail.initial_form.questions.length > 0 ? props.detail.initial_form.id : null, // number
                "answers": tmp
            }
        }
        OS.bookOpportunity(props.detail.info.id, body).then((data) => {
            setOpenBookModal(false)
            setOpenFormModal(false)
            setAnswers([])
            props.reload()
        }).catch(() => {
            setOpenBookModal(false)
            setOpenFormModal(false)
            setAnswers([])
            props.reload()
        })

    }

    const removeOpportunityBooking = () => {
        OS.removeOpportunityBooking(props.detail.info.id).then((data) => {
            setAnswers([])
            setSelectedSlot([])
            props.reload()
        }).catch(() => {
            setAnswers([])
            setSelectedSlot([])
            props.reload()
        })
    }

    const deleteOpportunity = () => {
        OS.deleteOpportunity(props.detail.info.id).then((data) => {
            setAnswers([])
            setSelectedSlot([])
            props.handleRemoveOpportunity()
        }).catch(() => {
            setAnswers([])
            setSelectedSlot([])
            props.handleRemoveOpportunity()
        })
    }

    let customConfirmModalStyles = {
        content: {
            top: window.innerWidth > 1000 ? '50%' : '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            "min-height": '220px',
            marginRight: '-50%',
            marginTop: '20px',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": window.innerWidth > 1000 ? "#fff" : "#f0efef"
        }
    };


    const customStylesServices = {
        control: (base, { isFocused, isSelected }) => ({
            ...base,
            background: window.innerWidth > 1000 ? "#fff" : "#f0efef",
            minHeight: '30px',
            height: '30px',
            width: '300px',
            border: 0,
            color: "black",
            // Overwrittes the different states of border
            borderColor: isSelected ? null : null,
            // Removes weird border around container
            boxShadow: isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            color: "black",
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',

        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
        option: (styles, { isFocused, isHovered }) => ({
            ...styles,
            backgroundColor: isFocused || isHovered ? "#c5c5c7" : "transparent",
            color: "black",
            "&:hover": {
                backgroundColor: "#c5c5c7"
            },
            color: '#000',
            width: '300px',
            zIndex: 1
        }),
        menuList: (base, { isFocused, isSelected }) => ({
            ...base,
            background: window.innerWidth > 1000 ? "#fff" : "#f0efef",
            // kill the white space on first and last option
            padding: 0,
            width: '300px',
            "max-height": "100px",
            "::-webkit-scrollbar-thumb": {
                background: "#000 !important"
            },
        })
    };

    const handleCheckbox = (e, id, answer) => {
        let form = props.detail.initial_form
        form.questions.map((elem) => {
            if (elem.id == id) {
                elem.answer = answer
                elem.answer_id = e.target.id
            }
        })
        setAnswers(form)
    }

    const handleText = (answer, id) => {
        let form = props.detail.initial_form
        form.questions.map((elem) => {
            if (elem.id == id) {
                elem.answer = answer.target.value
            }
        })
        setAnswers(form)
    }

    const handleNextView = () => {
        let ret = true
        if (answers.questions && answers.questions.length > 0)
            answers.questions.map((a) => {
                if (!a.answer)
                    ret = false
            })
        else
            ret = false
        if (!ret) return UseMessage(getTranslationByKey('OPPORTUNITIES.LABLE.WARNING.ALLFIELDSREQUIRED'))
        setNextView(true)
    }

    const addToWishlist = (e) => {
        e.stopPropagation()
        OS.addToWishlist(props.detail.info.id).then(() => {
            props.reload()
        })
    }
    const removeFromWishlist = (e) => {
        e.stopPropagation()
        OS.removeFromWishlist(props.detail.info.id).then(() => {
            props.reload()
        })
    }


    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = ("0" + new_date.getDate()).slice(-2).toString() + '/' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '/' + new_date.getFullYear().toString()
        return toStringDate
    }

    const getSlot = (slot) => {
        if (slot && slot.start_date && slot.end_date) {
            let sd = new Date(slot.start_date)
            let date = getData(sd)
            let ed = new Date(slot.end_date)
            let sd_string = (sd.getHours() > 9 ? sd.getHours() : '0' + sd.getHours()) + ':' + (sd.getMinutes() > 9 ? sd.getMinutes() : '0' + sd.getMinutes())
            let ed_string = (ed.getHours() > 9 ? ed.getHours() : '0' + ed.getHours()) + ':' + (ed.getMinutes() > 9 ? ed.getMinutes() : '0' + ed.getMinutes())
            return date + ', ' + sd_string + ' - ' + ed_string
        }
        else
            return ''
    }

    const Tooltip = <Popover id="popover-basic" Style={'box-shadow: "-1px 2px 5px rgba(0,0,0,0.1)"; max-width: 220px;'}>
        <div Style={'padding:10px'}>
            {props.detail.info && props.detail.info.visibility_list ?
                props.detail.info.visibility_list.map((vl, index) => <div>{vl.Name}{index < (props.detail.info.visibility_list.length - 1) ? ',' : ''}</div>)
                : ''}
        </div>
    </Popover >

    return (
        <div>
            <div className="opportunities_info_cont" Style="padding: 20px; align-items: center;">
                <div className="flex" >

                    <div>
                        <div className="bold">{props.detail.info.title}</div>
                    </div>
                    <div className="flex" Style="margin-left: auto;">
                        {window.innerWidth > 1000 ?
                            (props.detail.info.is_opportunity_admin && !props.userView ?
                                '' :
                                (props.detail.info.is_booked && props.detail.info.type.id == 1 ?
                                    <div className="applied_button pointer">{getTranslationByKey('MODULE.OPPORTUNITIES.PROJECTBOOKED')}</div>
                                    :
                                    (props.detail.info.type.id == 2 ?
                                        props.detail.booking_deletable ?
                                            (props.detail.info.is_in_waiting_list ?
                                                <div className="show_interest_button delete_button pointer" onClick={() => removeOpportunityBooking()}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.EXITWAITINGLIST')}</div>
                                                :
                                                <div className="show_interest_button delete_button pointer" onClick={() => removeOpportunityBooking()}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.DELETEBOOKING')}</div>)
                                            :
                                            props.detail.bookable ?
                                                (props.detail.is_full ?
                                                    <div className="show_interest_button pointer" onClick={() => bookOpportunity()}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.WAITINGLIST')}</div>
                                                    :
                                                    <div className="show_interest_button pointer" onClick={() => setOpenBookModal(!openBookModal)}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.BOOK')}</div>
                                                )
                                                :
                                                (props.detail.full_button ?
                                                    <div className="applied_button">{getTranslationByKey('OPPORTUNITIES.LABLE.FULL')}</div>
                                                    : '')
                                        :
                                        props.detail.bookable ?
                                            <div className="show_interest_button pointer" onClick={() => setOpenFormModal(!openFormModal)}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.SHOWINTEREST')}</div> :
                                            (props.detail.full_button ?
                                                <div className="applied_button">{getTranslationByKey('OPPORTUNITIES.LABLE.CLOSED')}</div>
                                                : '')
                                    )
                                )) : ''}
                        {/*
                    <div className="pointer" Style="padding-top: 2px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                        </svg>
                    </div>
                    */}

                        <div Style="margin-left: 10px; padding-top: 2px;">

                            {props.detail.info.is_opportunity_admin && !props.userView ?
                                <div className="flex vertical_center">
                                    <div className="show_interest_button delete_button pointer" onClick={() => setOpenConfirmModal(true)}>{getTranslationByKey('OPPORTUNITIES.BUTTON.DELETE')}</div>
                                    {props.detail.info.is_draft ? '' :
                                        <div className="pointer" Style="margin-right: 10px;" onClick={() => props.handleDuplicateView()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                            </svg>
                                        </div>
                                    }
                                    <div className="pointer" onClick={() => props.setUpdateView(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </div>
                                </div>
                                :
                                (!props.detail.info.is_in_wishlist ?
                                    <div className="pointer" onClick={(e) => addToWishlist(e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                        </svg>
                                    </div>
                                    :
                                    <div className="pointer" onClick={(e) => removeFromWishlist(e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                        </svg>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>

                {window.innerWidth < 1000 ?
                    <div Style="text-align: -webkit-right;">
                        {props.detail.info.is_opportunity_admin && !props.userView ?
                            ''
                            :
                            (props.detail.info.is_booked && props.detail.info.type.id == 1 ?
                                <div className="applied_button pointer">{getTranslationByKey('MODULE.OPPORTUNITIES.PROJECTBOOKED')}</div>
                                :
                                (props.detail.info.type.id == 2 ?
                                    props.detail.booking_deletable ?
                                        (props.detail.info.is_in_waiting_list ?
                                            <div className="show_interest_button delete_button pointer" onClick={() => removeOpportunityBooking()}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.EXITWAITINGLIST')}</div>
                                            :
                                            <div className="show_interest_button delete_button pointer" onClick={() => removeOpportunityBooking()}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.DELETEBOOKING')}</div>)
                                        :
                                        props.detail.bookable ?
                                            (props.detail.is_full ?
                                                <div className="show_interest_button pointer" onClick={() => bookOpportunity()}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.WAITINGLIST')}</div>
                                                :
                                                <div className="show_interest_button pointer" onClick={() => setOpenBookModal(!openBookModal)}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.BOOK')}</div>
                                            )
                                            :
                                            (props.detail.full_button ?
                                                <div className="applied_button">{getTranslationByKey('OPPORTUNITIES.LABLE.FULL')}</div>
                                                : '')
                                    :
                                    props.detail.bookable ?
                                        <div className="show_interest_button pointer" onClick={() => setOpenFormModal(!openFormModal)}>{getTranslationByKey('OPPORTUNITIES.QUESTIONARY.SHOWINTEREST')}</div>
                                        :
                                        (props.detail.full_button ?
                                            <div className="applied_button">{getTranslationByKey('OPPORTUNITIES.LABLE.CLOSED')}</div>
                                            : '')

                                ))
                        }
                    </div>
                    : ''}
            </div>
            <div className="opportunities_detail_box">
                <div className="opportunities_detail_box_container">
                    <div className="opportunities_line_info_mobile">
                        <div className="flex item-center opportunities_mobile_width">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-geo-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411" />
                                </svg>
                            </div>
                            <div className="opportunities_detail_box_title">{props.detail.info.location ?? '-'}</div>
                        </div>
                        <div className="flex item-center opportunities_mobile_width">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                </svg>
                            </div>
                            <div className="opportunities_detail_box_title" Style={"width: 300px;display: flex;"}>{props.detail.info.duration_string ?? '-'}</div>
                        </div>
                    </div>
                    <div className="opportunities_line_info_mobile" Style="margin-left: auto;">
                        <div className="flex item-center opportunities_mobile_width">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                </svg>
                            </div>
                            <div className="opportunities_detail_box_title">{props.getData(props.detail.info.start_date)}</div>
                        </div>
                        <div className="flex item-center opportunities_mobile_width">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                </svg>
                            </div>
                            <div className="opportunities_detail_box_title">{props.detail.info.created_by ?? '-'}</div>
                        </div>
                    </div>
                    <div className="opportunities_line_info_mobile" Style="margin-left: auto;">
                        <div className="flex item-center opportunities_mobile_width">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-diagram-2-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5zm-3 8A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5z" />
                                </svg>
                            </div>
                            <div className="opportunities_detail_box_title">{props.detail.info.department ?? '-'}</div>
                        </div>
                        {props.userView ? '' :
                            <div className="flex item-center opportunities_mobile_width">
                                <div>
                                    <OverlayTrigger
                                        placement="top"
                                        trigger="click"
                                        rootClose
                                        overlay={Tooltip}
                                    >
                                        <Form.Text className="show_more">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                            </svg>
                                        </Form.Text>
                                    </OverlayTrigger>
                                </div>
                                <div className="opportunities_detail_box_title">{props.detail.info && props.detail.info.visibility_list ? props.detail.info.visibility_list.length : '-'}/6</div>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex mt-2">
                    {props.detail && props.detail && props.detail.booked_slot
                        ? <div>
                            <div className="flex item-center">
                                <div className="opportunities_detail_box_title bold">
                                    {getTranslationByKey('OPPORTUNITIES.LABLE.SELECTEDSLOT')} </div>
                            </div>
                            <div className="opportunities_detail_box_title ">
                                {getSlot(props.detail.booked_slot)} </div>

                        </div> : ''}
                </div>
            </div>
            <Modal
                isOpen={openBookModal}
                onAfterOpen={null}
                onRequestClose={() => setOpenBookModal(false)}
                style={customConfirmModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
                preventScroll={true}
            >
                <div className="close" >
                    <svg onClick={() => setOpenBookModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="modal_body">
                    <div>
                        <div Style="margin: 40px 0;">
                            <Select
                                value={selectedSlot}
                                onChange={setSelectedSlot}
                                options={slots}
                                className="opportunities_interested_select opportunities_slot_select pt-1"
                                placeholder={getTranslationByKey('OPPORTUNITIES.LABLE.SELECTSLOT')}
                                styles={customStylesServices}
                                blurInputOnSelect={true}
                                onMenuClose={() => window.scrollTo(0, 0, 'smooth')}
                            /></div>
                        <div className="opportunities_action_button_container flex">
                            <div className="opportunities_action_button bold pointer flex" onClick={() => bookOpportunity()}>
                                <div className="bold">
                                    {getTranslationByKey('OPPORTUNITIES.QUESTIONARY.BOOK')}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </Modal >


            <Modal
                isOpen={openFormModal}
                onAfterOpen={null}
                onRequestClose={() => setOpenFormModal(false)}
                style={customConfirmModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
                preventScroll={true}
            >
                <div className="close" >
                    <svg onClick={() => setOpenFormModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="modal_body">
                    <div className="opportunities_form_modal_body">
                        {props.detail && props.detail.initial_form ?
                            props.detail.initial_form.questions.map((q, index) =>
                                <OpenQuestions q={q} handleText={handleText} />
                            )
                            : ''}

                        {props.detail.info.type.id == 1 ? <div className="bold">
                            {getTranslationByKey('OPPORTUNITIES.LABLE.WARNING.BOOKONLYIFSURE')}
                        </div> : ''}
                    </div>
                    <div className="opportunities_action_button_container">
                        <div className="opportunities_action_button bold pointer flex" onClick={() => bookOpportunity()}>
                            <div className="bold">
                                {getTranslationByKey('OPPORTUNITIES.QUESTIONARY.SHOWINTEREST')}
                            </div>
                        </div>
                    </div>
                </div>

            </Modal >

            <Modal
                isOpen={openConfirmModal}
                onAfterOpen={null}
                onRequestClose={() => setOpenFormModal(false)}
                style={customConfirmModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
                preventScroll={true}
            >
                <div className="close" >
                    <svg onClick={() => setOpenConfirmModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="modal_body">
                    <div Style="margin-top: 10px;">
                        <Markdown>{getTranslationByKey('OPPORTUNITIES.LABLE.WARNING.AREYOUSUREDELETING')}</Markdown>
                    </div>
                    <div className="opportunities_action_button_container flex">
                        <div className="opportunities_action_white_button bold pointer flex" Style="margin-right: 10px;" onClick={() => setOpenConfirmModal(false)}>
                            <div className="bold">
                                {getTranslationByKey('OPPORTUNITIES.BUTTON.CANCEL')}
                            </div>
                        </div>
                        <div className="opportunities_action_button bold pointer flex" onClick={() => deleteOpportunity()}>
                            <div className="bold">
                                {getTranslationByKey('OPPORTUNITIES.BUTTON.DELETE')}
                            </div>
                        </div>
                    </div>
                </div>

            </Modal >
        </div >)
}