import React, { useEffect, useState } from "react"
import { ShareToGoService } from "../Services/Services"
import Markdown from 'markdown-to-jsx'

import Modal from 'react-modal';
import { getTranslationByKey } from "../global";

export function ShareToGoCard(props) {

    const S2GService = new ShareToGoService
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = ("0" + new_date.getDate()).slice(-2).toString() + '/' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '/' + new_date.getFullYear().toString()
        return toStringDate
    }

    const deleteBooking = () => {
        setOpenDeleteModal(false)
        S2GService.deleteRide(props.detail.id).then((data) => {
            props.reload()
        })
    }

    let customConfirmModalStyles = {
        content: {
            top: window.innerWidth > 1000 ? '50%' : '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            "min-height": '220px',
            marginRight: '-50%',
            marginTop: '20px',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": window.innerWidth > 1000 ? "#fff" : "#f0efef"
        }
    };

    return (
        <React.Fragment>

            <div className={props.detail.is_deletable ? "share_to_go_card " : "share_to_go_card share_to_go_card_deleted"}>
                <div className="flex" Style="align-items: center;">
                    <div Style="text-align: left;" >
                        <div className="share2go_card_line"><span className="bold">{getTranslationByKey('SHARETOGO.MYBOOKINGS.FROMTO')}:</span> {getData(props.detail.start_date)} - {getData(props.detail.end_date)}</div>
                    </div>
                    {props.detail.is_deletable ?
                        <div className="pointer" Style="margin-left: auto;" onClick={() => setOpenDeleteModal(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                            </svg>
                        </div>
                        :
                        ''
                    }
                </div>
                <div Style="padding-right: 25px;">
                    <div className="share2go_card_line"><span className="bold">{getTranslationByKey('SHARETOGO.MYBOOKINGS.CARPARK')}:</span> {props.detail.car_park ? props.detail.car_park.name : ''}</div>
                </div>
                <div>
                    <div className="share2go_card_line"><span className="bold">{getTranslationByKey('SHARETOGO.MYBOOKINGS.QUEUENUMBER')}:</span> {props.detail.queue_position}</div>
                </div>
                <div >
                    <div className="share2go_card_line"><span className="bold">{getTranslationByKey('SHARETOGO.MYBOOKINGS.RESERVATIONNUMBER')}:</span> {props.detail.id}</div>
                </div>
                {props.detail.assigned_car ?
                    <div >
                        <div className="share2go_card_line"><span className="bold">{getTranslationByKey('SHARETOGO.MYBOOKINGS.AUTO')}:</span> {props.detail.assigned_car.brand}, {props.detail.assigned_car.plate}</div>
                    </div>
                    : ''}
                <div className="share_to_go_status_card_cont">
                    <div className={props.detail.is_deletable ? "share_to_go_status_card" : "share_to_go_status_card status_deleted"}>
                        <div>{getTranslationByKey('SHARETOGO.MYBOOKINGS.STATUS')}: {getTranslationByKey(props.detail.status)}</div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={openDeleteModal}
                onAfterOpen={null}
                onRequestClose={() => setOpenDeleteModal(false)}
                style={customConfirmModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
                preventScroll={true}
            >
                <div className="close" >
                    <svg onClick={() => setOpenDeleteModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="modal_body">
                    <div Style="margin-top: 10px;">
                        <div className="bold">{getTranslationByKey('SHARETOGO.BOOKING.AREYOUSURE')}</div>
                        <br />
                        <div>
                            <div>{getTranslationByKey('SHARETOGO.BOOKING.FROM')}: <span className="bold">{getData(props.detail.start_date)}</span> </div>
                            <div><span Style="text-transform: capitalize;">{getTranslationByKey('SHARETOGO.BOOKING.TO')}</span>: <span className="bold">{getData(props.detail.end_date)}</span></div>
                            <div>{getTranslationByKey('SHARETOGO.MYBOOKINGS.CARPARK')}: <span className="bold">{props.detail.car_park ? props.detail.car_park.name : ''}</span></div>
                            <div Style="margin-top: 10px;"><Markdown>{getTranslationByKey('SHARETOGO.BOOKING.CONFIRMDELETE')}</Markdown></div>
                        </div>
                    </div>
                    <div className="opportunities_action_button_container flex">
                        <div className="opportunities_action_white_button bold pointer flex" Style="margin-right: 10px;" onClick={() => setOpenDeleteModal(false)}>
                            <div className="bold">
                                {getTranslationByKey('OPPORTUNITIES.BUTTON.CANCEL')}
                            </div>
                        </div>
                        <div className="opportunities_action_button bold pointer flex" onClick={() => deleteBooking()}>
                            <div className="bold">
                                {getTranslationByKey('OPPORTUNITIES.LABLE.CONFIRM')}
                            </div>
                        </div>
                    </div>
                </div>

            </Modal >
        </React.Fragment>
    )
}