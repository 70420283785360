import React, { useEffect, useState } from "react"
import { ShareToGoForm } from "./ShareToGoForm";
import { ShareToGoBookings } from "./ShareToGoBookings";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap"
import { ShareToGoDocumentList } from "./ShareToGoDocumentList";
import { UseAlertMessage } from "./Alert";
import { InternalBackButton } from "./InternalBackButton";
import { ShareToGoService } from "../Services/Services";
import { LoadingComponentWeb } from "./LoadingComponent";
import { getTranslationByKey } from "../global";

export function ShareToGo(props) {


    const S2GService = new ShareToGoService

    const [isLoading, setLoading] = useState(true);
    const [isLoadingBookings, setLoadingBookings] = useState(true);
    const [selectedMenu, setSelectedMenu] = useState('bookings');
    const [docApprove, setDocApprove] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState('');
    const [bookings, setBookings] = useState([]);
    const [prevBookings, setPrevBookings] = useState([]);
    const [hasPrevious, setHasPrevious] = useState(false);
    const [prevMonth, setPrevMonth] = useState('');
    const [nextBookings, setBookingsNextSections] = useState([]);
    const [documentURL, setDocumentURL] = useState('');
    const [share2GoOptions, setShare2GoOptions] = useState('');

    useEffect(() => {
        if (props.routingObj && props.routingObj.path) {
            let id = props.routingObj.path.split('/sharetogo/')[1]
            if (id)
                setSelectedMenu(id.replace('/', ''))
            else
                setSelectedMenu('bookings')
        }
    }, [])


    useEffect(() => {
        let startDate = ''
        let endDate = ''
        let bodyDate = formatData(new Date)

        S2GService.getS2GOptions().then((data) => {
            setShare2GoOptions(data)
            setDocApprove(data.user_enabled)
            startDate = formatData(data.min_request_date)
            endDate = formatData(data.max_request_date)
            S2GService.getRides(bodyDate).then((data) => {
                setBookings(data.ride_groups)
                setHasPrevious(data.has_previous)
                setPrevMonth(data.previous_month)
                if (data.next_month) {
                    getNextMonthRides(data.next_month)
                } else {
                    setLoading(false)
                }
            })
        })


    }, [])

    useEffect(() => {
        setSelectedDocument('')
    }, [selectedMenu])

    const getNextMonthRides = (d) => {
        S2GService.getRides(d).then((data) => {
            setBookingsNextSections(data.ride_groups)
            setLoading(false)
        })
    }

    const handleSeeMore = (date) => {
        let d = new Date(date)
        d.setMonth(d.getMonth() - 2);
        d.setDate('01')
        d = formatData(d)
        let tmp = prevBookings
        S2GService.getRidesByMonth(d, date).then((data) => {
            data.ride_groups.map((r) => {
                tmp.push(r)
            })
            setPrevBookings([...tmp])
            setHasPrevious(data.has_previous)
            setPrevMonth(data.previous_month)
            setLoadingBookings(false)
        })
    }

    const formatData = (date) => {
        let new_date = new Date(date)
        let bodyDate = ""
        bodyDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
        return bodyDate
    }

    const handleBackDocumentView = () => {
        setSelectedDocument('')
    }

    const handleSelectedDocument = (document, url) => {
        S2GService.getDocumentLink(url).then((data) => {

        })
        setDocumentURL(url)
        setSelectedDocument(document)

    }

    const handleSetDocApprove = () => {
        if (isAccepted) {
            S2GService.setEnableUser().then((data) => {
                setDocApprove(true)
            })
        } else
            UseAlertMessage(404, getTranslationByKey('SHARETOGO.ALERT.CONDITIONS'))
    }

    return (
        <div>
            {isLoading ?
                <LoadingComponentWeb />
                :
                <div>
                    {selectedDocument && window.innerWidth > 1000 ?
                        <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={() => handleBackDocumentView()} index={99} />
                        : ''}
                    <div className="share_to_go_padding" Style={window.innerWidth > 1000 ? "padding: 30px;" : ''}>
                        <div className="share_to_go_img">
                            <div className="share_to_go_img_title bold">
                                {getTranslationByKey('SHARETOGO.TITLE')}
                            </div>
                        </div>
                        {!docApprove ?
                            <div Style="margin-top: 30px;" className="share2go_document_overflow">
                                <ShareToGoDocumentList documentURL={documentURL} user={props.user} selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument} handleSelectedDocument={handleSelectedDocument} />
                                {selectedDocument ? '' :
                                    <React.Fragment>
                                        <div Style="margin-top: 30px;" className="share2go_checkbox">
                                            <input checked={isAccepted} type="checkbox" id="isAcceptedCheckbox" name="isAcceptedCheckbox" onChange={() => setIsAccepted(!isAccepted)} />
                                            <label id="isAcceptedCheckbox_label" Style={"padding-left: 10px;"} >{getTranslationByKey('SHARETOGO.MESSAGE.READANDAGREE')}</label>
                                        </div>
                                        <div Style="margin-top: 30px;">
                                            <div className="share_to_go_doc_button pointer" onClick={() => handleSetDocApprove()}>{getTranslationByKey('SHARETOGO.BUTTON.NEXT')}</div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            :
                            <div>
                                <div className="share_to_go_buttons_container_padding">
                                    <div className="feed_buttons_container share_to_go_buttons_container">
                                        <div className={selectedMenu === 'book' ? "feed_button_selected s2g_button bold" : "feed_button s2g_button pointer"} onClick={() => setSelectedMenu('book')}>{getTranslationByKey('SHARETOGO.TITLE.BOOK')}</div>
                                        <div className={selectedMenu === 'bookings' ? "feed_button_selected s2g_button bold" : "feed_button s2g_button pointer"} onClick={() => setSelectedMenu('bookings')}>{getTranslationByKey('SHARETOGO.TITLE.MYBOOKINGS')}</div>
                                        <div className={selectedMenu === 'regulations' ? "feed_button_selected s2g_button bold" : "feed_button s2g_button pointer"} onClick={() => setSelectedMenu('regulations')}>{getTranslationByKey('SHARETOGO.TITLE.REGULATIONS')}</div>
                                    </div>
                                </div>
                                <div>
                                    {selectedMenu === 'book' ?
                                        <ShareToGoForm user={props.user} bookings={bookings} share2GoOptions={share2GoOptions} />
                                        :
                                        selectedMenu === 'regulations' ?
                                            <div>
                                                <ShareToGoDocumentList documentURL={documentURL} user={props.user} selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument} handleSelectedDocument={handleSelectedDocument} />
                                            </div>
                                            :
                                            <ShareToGoBookings isLoadingBookings={isLoadingBookings} setLoadingBookings={setLoadingBookings} prevMonth={prevMonth} handleSeeMore={handleSeeMore} hasPrevious={hasPrevious} prevBookings={prevBookings} bookings={bookings} nextBookings={nextBookings} />
                                    }
                                </div>
                            </div>
                        }
                    </div >
                </div>
            }
        </div>
    )
}