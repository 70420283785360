import React, { useEffect, useState } from "react"
import { ShareToGoCard } from "./ShareToGoCard";
import ScrollContainer from 'react-indiana-drag-scroll'
import { Carousel } from 'react-responsive-carousel';
import { getTranslationByKey } from "../global";

export function ShareToGoBookingsSection(props) {

    const splitInNChunks = (array) => {
        let n = 3
        let [...arr] = array;
        var res = [];
        while (arr.length) {
            res.push(arr.splice(0, n));
        }
        return res;
    }

    const getCarousel = () => {
        let ret = ''
        let retEmpty = <div Style="font-size: 14px;">{getTranslationByKey('SHARETOGO.MYBOOKINGS.NORIDES')}</div>
        let tmp = props.section && props.section.rides && props.section.rides.length > 0 ? splitInNChunks(props.section.rides) : ''
        ret = props.section && props.section.rides && props.section.rides.length > 0 ?
            tmp.map((t, i) =>
                <div className="flex opportunities_carousel_content s2g_carousel_content">
                    <React.Fragment>
                        {t.map((bc) =>
                            <ShareToGoCard key={bc.id} detail={bc} reload={props.reload} />
                        )}
                        {/*props.has_more && i == (tmp.length - 1) ? view_all_card : ''*/}
                    </React.Fragment>
                </div>
            )
            : ''
        let ret2 =
            <div Style="width: 98%; margin-top: 20px;">
                <Carousel
                    centerSlidePercentage={50}
                    interval={4000}
                    infiniteLoop={true}
                    transitionTime={1000}
                    showThumbs={false}
                    showStatus={false}
                    renderArrowPrev={(clickHandler, hasPrev) => {
                        return (
                            <div Style="min-width: 22px;" className='pointer' onClick={clickHandler}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                                </svg>
                            </div>
                        );
                    }}
                    renderArrowNext={(clickHandler, hasNext) => {
                        return (
                            <div Style="min-width: 22px;" className='pointer' onClick={clickHandler} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </div>
                        );
                    }}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const selected = { marginLeft: -10, color: "#535353", cursor: "pointer" };
                        const notSelected = { marginLeft: -10, color: "#c2c2c2", cursor: "pointer" };
                        const style = isSelected
                            ? { ...selected, color: "#535353" }
                            : { ...notSelected };
                        return (
                            <span
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-dot pointer" viewBox="0 0 16 16">
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                </svg>

                            </span>
                        );
                    }}
                >
                    {ret}
                </Carousel>
            </div>
        let ret3 = <ScrollContainer className="flex">
            {props.section && props.section.rides && props.section.rides.length > 0 ?
                props.section.rides.map((bc, i) =>
                    <div className="flex opportunities_carousel_content s2g_carousel_content">
                        <React.Fragment>
                            <ShareToGoCard key={bc.id} detail={bc} reload={props.reload} />
                        </React.Fragment>
                    </div>
                )
                : ''}
        </ScrollContainer>

        if (props.section && props.section.rides && props.section.rides.length > 0)
            if (window.innerWidth > 1000)
                return ret2
            else
                return ret3
        else
            return retEmpty
    }


    return (
        <div Style="margin-bottom: -20px;">
            <div className="share_to_go_section_line">
                {getCarousel()}
            </div>
        </div>
    )
}