import * as pdfjsLib from 'pdfjs-dist'
import { useEffect, useState } from 'react'
import { BusinessTravelService } from '../Services/Services'
import { LoadingComponentWeb } from './LoadingComponent'

export function PDFDocument(props) {
    const [doc, setDoc] = useState()
    const [docUrl, setDocUrl] = useState()
    const [loading, setLoading] = useState(true)
    let bs = new BusinessTravelService()

    const fetchData = async (a) => {

        if (props.url) {
            const loadingTask = pdfjsLib.getDocument({ data: atob(a.split('base64,')[1]) })
            await loadingTask.promise.then(renderPages);
        }
    }

    function renderPages(pdfDoc) {
        for (var num = 1; num <= pdfDoc.numPages; num++)
            pdfDoc.getPage(num).then(renderPage);
    }

    function renderPage(page) {

        var viewport = page.getViewport({ scale: 1.0 });
        let ratio = ((window.innerWidth - 400) > 900 ? 900 : (window.innerWidth - 400)) / viewport.width
        if (window.innerWidth < 1300)
            ratio = ((window.innerWidth - 400) > 745 ? 745 : (window.innerWidth - 400)) / viewport.width
        if (window.innerWidth < 1200)
            ratio = ((window.innerWidth - 400) > 645 ? 645 : (window.innerWidth - 400)) / viewport.width
        viewport = page.getViewport({ scale: ratio });

        var wrapper = document.createElement("div");
        wrapper.className = "canvas-wrapper";
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var renderContext = {
            canvasContext: ctx,
            viewport: viewport
        };

        canvas.height = viewport.height;
        canvas.width = viewport.width;
        wrapper.appendChild(canvas)
        document.getElementById('my_canva_container').appendChild(wrapper);

        page.render(renderContext);
    }

    useEffect(() => {
        setLoading(true)
        if (document.getElementById('my_canva_container')) {
            document.getElementById('my_canva_container').innerHTML = ''
            if (document.getElementsByClassName('central_component')[0])
                document.getElementsByClassName('central_component')[0].scrollTo(0, 0)
        }
        if (window.innerWidth > 1000)
            bs.getDocument(props.url, false).then((data) => {

                setDoc(data)
                setDocUrl(props.url)
                fetchData(data)
                setLoading(false)
            })
        else
            downloadDocUrl(props.url)
    }, [props.url])

    const downloadDoc = () => {
        bs.getDocument(docUrl, true)
    }

    const downloadDocUrl = (url) => {
        bs.getDocument(url, true).finally(() => {
            if (props.isShare2GoUrl) {
                props.setSelectedDocument('')
            }
        })
    }

    return (
        loading ? <LoadingComponentWeb />
            :
            <div Style='position:relative'>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-download doc_download_ico" viewBox="0 0 16 16" onClick={downloadDoc}>
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg>
                <div id='my_canva_container'>
                </div>
            </div>
    )
}