import React, { useEffect, useState } from "react"
import { ShareToGoDocumentCard } from "./ShareToGoDocumentCard"
import { PDFDocument } from "./PdfDocument";

export function ShareToGoDocumentList(props) {


    useEffect(() => {
    }, [])

    return (
        <div className="">
            {props.selectedDocument === '' ?
                <div className="share_to_go_documents_list">
                    {props.user.modules && props.user.modules.share2go && props.user.modules.share2go.resources ?
                        props.user.modules.share2go.resources.map((r) =>
                            <ShareToGoDocumentCard handleSelectedDocument={props.handleSelectedDocument} url={r.file_url} name={r.title} description={r.description} setSelectedDocument={props.setSelectedDocument} />
                        )

                        : ''}
                </div>
                :
                <PDFDocument url={props.documentURL} isShare2GoUrl={true} setSelectedDocument={props.setSelectedDocument} />}

        </div>
    )
}