import axios from 'axios';
import { Component } from 'react';
import Cookies from 'universal-cookie';
import { loginRequest, msalInstance, msalConfig } from '../authConfig';
import { useToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import { UseAlert, UseAlertMessage, UseMessage } from '../View/Alert';

var BASE_URL = getApiUrl()
var cookies = new Cookies();

//dynamic url managing
function getApiUrl() {
    //cn
    if (window.location.hostname === 'gucciforyou.gucci.cn') {
        return "https://smartoffice-api.gucci.cn/api"
    }
    //prod
    else if (window.location.hostname === 'gucciforyou.gucci.com')
        return "https://smartoffice.globalbit.gucci/api"
    //stag
    else {
        return "https://smartoffice.globalbit.gucci/staging_api"
    }
}

//translation applanga function
export function getTranslationByKey(key) {
    let translation = ''
    let translation_en = ''
    if (sessionStorage.getItem('translation')) {
        translation = JSON.parse(sessionStorage.getItem('translation'))
        translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
        return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
    } else {
        return key
    }
}

//get and automatic refresh token
const getToken = () => {
    // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Inp4ZWcyV09OcFRrd041R21lWWN1VGR0QzZKMCJ9.eyJhdWQiOiI3ZTU2YTY1YS0xOGMyLTRkNzQtODQ0Zi03ZDEzYWEzMjhmNWMiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vMmZmMDZhMDMtMWMyNC00MGY1LTlkM2ItODU0ZDkzYWFlZDdmL3YyLjAiLCJpYXQiOjE3MzQ0NDQ2MzAsIm5iZiI6MTczNDQ0NDYzMCwiZXhwIjoxNzM0NDQ4NTMwLCJhaW8iOiJBVlFBcS84WUFBQUFtNjc1NXJNdUduYkhXM0IvRS9LME5ESFE2U2tDOTRZeW5qcXZoUS9ZeVBSWkZ6clEwekFUS3ZZWUs4RVZUV3hxRDduYVFHOG4wZFMwdmw4amM4cU8zMlg3bGV1Mmw1ajNTRjRTK0lVTGpVZz0iLCJuYW1lIjoiQ0FTQ0lPTEkgTWFyaWEgVGVyZXNhIChFeHRlcm5hbCkiLCJub25jZSI6IjRiNzRiZWRlLWJmZDEtNDdlMS1hOWFhLTlmY2NkNDU3YTAyMiIsIm9pZCI6IjQ2ZThlZjQxLTI5YTYtNDVhMS1iNGQxLTdmMWU3NDUwZjYwNiIsInByZWZlcnJlZF91c2VybmFtZSI6Im1hcmlhdGVyZXNhLmNhc2Npb2xpLWV4dEBndWNjaS5jb20iLCJyaCI6IjEuQVY4QUEycndMeVFjOVVDZE80Vk5rNnJ0ZjFxbVZuN0NHSFJOaEU5OUU2b3lqMXdQQVhKZkFBLiIsInN1YiI6IkRubGdTZXNlMEp6dVNPVXpwQnVvRnJoMkctbE9TdXBJbUctUU9sNlpZY0UiLCJ0aWQiOiIyZmYwNmEwMy0xYzI0LTQwZjUtOWQzYi04NTRkOTNhYWVkN2YiLCJ1dGkiOiJvdmlfeHFfb2JVYXJNbTMzNVkwVEFBIiwidmVyIjoiMi4wIiwic3VybmFtZSI6IkNBU0NJT0xJIiwibWFpbCI6Im1hcmlhdGVyZXNhLmNhc2Npb2xpLWV4dEBndWNjaS5jb20iLCJnaXZlbk5hbWUiOiJNYXJpYSBUZXJlc2EiLCJjb3VudHJ5IjoiSVQiLCJkZXBhcnRtZW50IjoiR3VjY2kifQ.Zwv3wLKLpcB7BISdk76j8BO6j0Jo3hyvzG-p3vvTXyQ82FTrMD0uMbU0ZyKQFYKP24nGHJElb9j0oX9f1CkAmRFiL_fePg-VrTiKv4emvIpbCoLL_cxWTq60r6xHH64QSvPdLpnjo2IvP3th-4wum3X-6PqNP3tnqJnMBgoFefOmdA3ojv7d-sp0a5F_rvMqonkp21bJS4AosGGJNJ83ywH9lqGqZ98FfLaSaRLHUIf0l-260QzBTJ15g24Cb-l8x9xzJMll08iXl8YzAywMFfHSaTrr0ZRjy49iDeRmAjD_3f3ERSawv3Im1fV-EgpkpDHLULE12RsB5IOfAhkoeQ'

    return new Promise(function (resolve, reject) {
        msalInstance.acquireTokenSilent({
            ...loginRequest,
            ...msalConfig.auth.authority,
            account: msalInstance.getAllAccounts()[0]
        }).then((response) => {
            cookies.set('user_token', response.idToken, { path: '/', maxAge: 31536000 });
            cookies.set('user_token_type', 'sso', { path: '/', maxAge: 31536000 });
            resolve(response.idToken);
        }).catch(function (error) {
            resolve(cookies.get("user_token"));
        });
    })
}

//get access token by the cookies
const getAccessToken = () => {


    return new Promise(function (resolve, reject) {
        msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: msalInstance.getAllAccounts()[0]
        }).then((response) => {
            resolve(response.accessToken);
        }).catch(function (error) {
            resolve(cookies.get("user_token"));
        });
    })
}

export class LoginService extends Component {

    //username and pass login
    async login(auth, checkTemporary) {
        axios.defaults.headers.common['Authorization'] = auth.toString();
        axios.get(BASE_URL + '/login', {
        }).then(function (response) {
            if (response.data.is_temporary_password) {
                checkTemporary(response.data.is_temporary_password)
            } else {
                checkTemporary(false)
                Promise.resolve(response);
                Promise.resolve(cookies.set('user_token_type', 'app', { path: '/', maxAge: 31536000 }));
                Promise.resolve(cookies.set('user_token', response.data.token, { path: '/', maxAge: 31536000 }));
                return response;
            }

        }).catch(function (error) {
            console.log(error.response.data.message)
            UseAlertMessage(400, getTranslationByKey(error.response.data.message) + '');
            return error;
        });
    }

    async changePassword(obj, returnToLogin) {
        axios.post(BASE_URL + '/user/changePassword', obj, {
        }).then(function (response) {
            // controlli response
            if (response.status == 200) {
                returnToLogin()
                UseAlertMessage(200, getTranslationByKey('UTIL.PASSWORD.CHANGE.SUCCESS'))
            }
            else if (response.status == 401) {
                UseAlert(401)
            }
            else {
                UseMessage(getTranslationByKey(response.message))
            }

        }).catch(function (error) {
            UseMessage(getTranslationByKey(error.response.data.message));
            return error;
        });
    }

    async resetPassword(email, returnToLogin) {
        fetch(BASE_URL + '/user/resetPassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getToken()
            },
            body: JSON.stringify({
                "email": email
            })
        }).then(function (response) {
            // controlli response
            if (response.status == 200) {
                returnToLogin()
                UseAlertMessage(200, getTranslationByKey('UTIL.PASSWORD.RESET.SUCCESS'))
            }
            else if (response.status == 401) {
                UseAlert(401)
            }
            else {
                UseAlertMessage(400, getTranslationByKey(response.message))
            }

        }).catch(function (error) {
            UseAlertMessage(400, getTranslationByKey(error.response.data.message));
            return error;
        });
    }
    //get notifications
    async getNotifications() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.get(BASE_URL + '/notification', {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error)
            });
        })
    }
    //get notifications
    async getNotificationsNumber() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.get(BASE_URL + '/notification/unread', {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error)
            });
        })
    }
    //read notification
    async readNotification(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.put(BASE_URL + '/notification/read/' + id, {

            }).then((response) => {
                resolve(response)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error)
            });
        })
    }
    //read notification
    async readNotifications() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.put(BASE_URL + '/notification/read', {

            }).then((response) => {
                resolve(response)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error)
            });
        })
    }
}

export class WellnessService extends Component {

    async getTranslation_new() {
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/applanga', { // applanga ufficiale
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(response => {
                return response.json()
            }).then(res => {
                let lang = navigator.language.split('-')[0]
                sessionStorage.setItem('translations', JSON.stringify(res.data))
                if (localStorage.getItem("selectedLanguage"))
                    lang = localStorage.getItem("selectedLanguage")

                if (res.data[lang])
                    sessionStorage.setItem('translation', JSON.stringify(res.data[lang].main.entries))
                else {
                    sessionStorage.setItem('translation', JSON.stringify(res.data["en"].main.entries))
                }
                sessionStorage.setItem('translation_en', JSON.stringify(res.data['en'].main.entries))
                resolve(res)
            }).catch((error) => {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error)
            })
        })
    }

    //get what's new
    async getWhatsNew() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/whats_new').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error.message == 401)
                    UseAlert(401)
                else
                    UseMessage(getTranslationByKey(error.response.data.message))
                reject(error);
            });
        })
    }

    //get user info v4
    async getUserInfov4() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/v6/user').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                UseAlert(401)
                reject(error);
            });
        })
    }

    //get wellness info
    async getScheduleByDate(d) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/wellness/schedule/date', d).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                /**/
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    //Update wellness calendar setting
    async updateWellnessCalendarSetting(d, showMessage) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/wellness/user/modify', d).then(function (response) {
                if (showMessage)
                    UseAlertMessage(200, response.data.message)
                resolve()
            }).catch(function (error) {
                /**/
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    //get wellness info
    async getCourseById(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/wellness/schedule/' + id).then(function (response) {
                // setCourse(response.data)
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getFilteredSchedule(options) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/wellness/schedule/v2/filter', options).then(function (response) {
                // setCourse(response.data)
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getGyms() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/wellness/gym').then(function (response) {
                //setGyms(response.data)
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getGymById(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/wellness/gym/' + id).then(function (response) {
                //setGym(response.data)
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getAllMyBookingsV2() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/wellness/v2/booking').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                UseMessage(getTranslationByKey(error.response.data.message))
                reject(error);
            });
        })
    }

    //insert booking
    async insertBooking(booking) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['access_token'] = await getAccessToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/wellness/v3/booking/insert', booking).then(function (response) {
                resolve(response)
            }).catch(function (error) {
                UseMessage(getTranslationByKey(error.response.data.message))
                reject(error);
            });
        })
    }

    async insertUserInWaitingList(booking) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/wellness/admin/booking/insertwaiting', booking).then(function (response) {
                setTimeout(function () { //Start the timer
                    UseMessage(getTranslationByKey('WELLNESS.UTIL.BOOKING.REMOVE'))
                    resolve()
                }.bind(this), 800)
            }).catch(function (error) {
                UseMessage(getTranslationByKey(error.response.data.message))
                reject(error);
            });
        })
    }

    //remove booking
    async removeBooking(booking_id, booking) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['access_token'] = await getAccessToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.delete(BASE_URL + '/wellness/booking/delete/' + booking_id, booking).then(function (response) {
                UseMessage(getTranslationByKey('WELLNESS.UTILS.REMOVEDBOOKING'))
                setTimeout(function () { //Start the timer
                    resolve()
                }.bind(this), 800)
            }).catch(function (error) {
                console.log(error)
                UseMessage(getTranslationByKey(error.response.data.message))
                reject(error);
            });
        })
    }

    async removeWaitingBooking(booking_id, booking) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.delete(BASE_URL + '/wellness/booking/deleteWaiting/' + booking_id, booking).then(function (response) {
                setTimeout(function () { //Start the timer
                    resolve()
                }.bind(this), 800)
            }).catch(function (error) {
                console.log(error)
                UseMessage(getTranslationByKey(error.response.data.message))
                setTimeout(function () { //Start the timer
                    reject(error)
                }.bind(this), 800)
            });
        })
    }

    //get trainers list
    async getTrainers() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/wellness/trainer').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }


    async getServices(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            //axios.get(BASE_URL + '/wellness/course/servicetype').then(function (response) {
            axios.get(BASE_URL + '/wellness/course/' + id + '/servicetype').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        });
    }


    async getUsersbyScheduleId(id, setUsers, setLoading) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/wellness/users/' + id).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                return error;
            });
        });
    }

    async addUserToCourse(booking) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/wellness/admin/booking/insert', booking).then(function (response) {
                setTimeout(function () { //Start the timer
                    resolve()
                }.bind(this), 800)
            }).catch(function (error) {
                UseMessage(getTranslationByKey(error.response.data.message))
                reject(error);
            });
        })
    }

    async getCoursebyScheduleId(id, setCourse) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/wellness/schedule/' + id).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        });
    }

    async followCourse(id, is_followed) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/wellness/schedule/follow/' + id).then(function (response) {
                UseMessage(is_followed ? getTranslationByKey('WELLNESS.COURSE.NOFOLLOW') : getTranslationByKey('WELLNESS.COURSE.FOLLOW'))
                resolve()
            }).catch(function (error) {
                UseMessage(getTranslationByKey(error.response.data.message))
                reject(error);
            });
        })
    }

    async setPresent(body, is_present) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/wellness/admin/insertpresence', body).then(function (response) {
                UseMessage(getTranslationByKey(is_present ? "WELLNESS.UTILS.PARTECIPATION.REMOVE" : "WELLNESS.UTILS.PARTECIPATION.ADD"))
                resolve()
            }).catch(function (error) {
                UseMessage(getTranslationByKey(error.response.data.message))
                reject(error);
            });
        })
    }
    async downloadPdf(path) {
        axios.defaults.headers.common['Authorization'] = await getToken();
        axios.defaults.headers.common['Content-Type'] = 'application/pdf'
        axios.get(BASE_URL + '/wellness/' + path, { responseType: 'blob' }).then(function (response) {
            const type = response.headers['content-type']

            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = path + '.pdf'
            link.click()

        }).catch(function (error) {
            if (error !== undefined && error.response !== undefined)
                UseMessage(getTranslationByKey(error.response.data.message));
            return error;
        });
    }

    async downloadWellnessPdf(path, val) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Content-Type'] = 'application/pdf'
            axios.get(BASE_URL + '/wellness/' + path, { responseType: 'blob' }).then(function (res) {
                console.log(res)
                const type = 'application/pdf'
                const blob = new Blob([res.data], { type: type })
                if (window.innerWidth <= 1000 || val == true) {
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = path + '.pdf'
                    link.click()
                }
                const fileReaderInstance = new FileReader();
                fileReaderInstance.readAsDataURL(blob);

                fileReaderInstance.onloadend = () => {
                    let base64data = fileReaderInstance.result.toString();
                    resolve(base64data)
                }
            }).catch((error) => {
                console.log(error)
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject('error')
            })
        })
    }

    async updateCourse(schedule_id, body) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/wellness/schedule/' + schedule_id + '/update', body).then(function (response) {
                resolve()
            }).catch(function (error) {
                UseAlert(getTranslationByKey(error.response.data.message))
                reject(error);
            });
        });
    }

    async getCourseList() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/wellness/course/type/1').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        });
    }

}

export class DeskService extends Component {
    //download
    async download(path) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.get(BASE_URL + '/' + path, { responseType: 'blob' }).then(function (response) {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], { type: type })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = path + '.mp4'
                link.click()
                resolve(response)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error)
            });
        })
    }

    async getTranslation() {
        let responseStatusUser = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/applanga', { // applanga ufficiale
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(response => {
                responseStatusUser = response.status
                return response.json()
            }).then(res => {
                let lang = navigator.language.split('-')[0]
                sessionStorage.setItem('translations', JSON.stringify(res.data))
                if (localStorage.getItem("selectedLanguage"))
                    lang = localStorage.getItem("selectedLanguage")
                if (res.data[lang])
                    sessionStorage.setItem('translation', JSON.stringify(res.data[lang].main.entries))
                else {
                    sessionStorage.setItem('translation', JSON.stringify(res.data["en"].main.entries))
                }
                sessionStorage.setItem('translation_en', JSON.stringify(res.data['en'].main.entries))
                resolve(res)
            }).catch((error) => {
                console.log(error.message)
                if (error.message == 401)
                    UseAlert(401)
                else if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message))
                reject(error)
            })
        })

    }

    async getTranslationByLanguage(language) {

        fetch(BASE_URL + '/applanga', {
            method: 'GET',
            headers: {
                'Authorization': await getToken(),
                'Language': navigator.language || navigator.userLanguage
            }
        }).then(response => {
            return response.json()
        }).then(res => {
            sessionStorage.setItem('translation_en', JSON.stringify(res.data['en'].main.entries))
            sessionStorage.setItem('translation', JSON.stringify(res.data[language].main.entries))
            sessionStorage.setItem('translations', JSON.stringify(res.data))

        }).catch(console.log);
    }

    async getUserProfile() {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/v5/user', {
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else if (responseStatus == 409) {
                    resolve(data)
                    UseMessage(getTranslationByKey(data.message));
                }
                else {
                    resolve(data)
                    UseMessage(getTranslationByKey('Error!'));
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }

    async getLocation(id) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/desk_booking/location/floors?locationID=' + id, {
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(responseFloor => {
                responseStatus = responseFloor.status
                return responseFloor.json()
            }).then(flr => {
                if (responseStatus == 200) {
                    resolve(flr)
                }
                else if (responseStatus == 401) {
                    const message = useToast({
                        message: 'Your booking was inserted in the waiting list!',
                        position: "bottomRight",
                        class: "btn btn-outline-warning",
                        timeout: 3000,
                        progressBar: true,
                        icon: 'warning',
                        color: '#e1ab09'
                    });
                    message()
                    resolve(flr)
                }
                else if (responseStatus == 409) {
                    UseMessage(getTranslationByKey(flr.message));
                    resolve(flr)
                }
                else {
                    UseMessage(getTranslationByKey(flr.message));
                    resolve(flr)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getUserBookings() {
        let responseStatusBookings = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/desk_booking/v3/user/bookings', {
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(response => {
                responseStatusBookings = response.status
                return response.json()
            }).then(data => {
                if (responseStatusBookings == 200) {
                    resolve(data)
                }
                else if (responseStatusBookings == 409) {
                    UseMessage(getTranslationByKey(data.message))
                }
                else if (responseStatusBookings == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getUserBookingsV3() {
        let responseStatusBookings = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/desk_booking/v3/user/bookings', {
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(response => {
                responseStatusBookings = response.status
                return response.json()
            }).then(data => {
                if (responseStatusBookings == 200) {
                    resolve(data)
                }
                else if (responseStatusBookings == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else if (responseStatusBookings == 409) {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getLocations() {
        return new Promise(async (resolve, reject) => {
            let responseStatusLocations = ''
            fetch(BASE_URL + '/desk_booking/v3/location', {
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            })
                .then(responseLocations => {
                    responseStatusLocations = responseLocations.status
                    return responseLocations.json()
                })
                .then(loc => {
                    if (responseStatusLocations == 200) {
                        resolve(loc)
                    }
                    else if (responseStatusLocations == 401) {
                        UseAlert(401)
                        resolve(loc)
                    }
                    else if (responseStatusLocations == 409) {
                        UseMessage(getTranslationByKey(loc.message))
                        resolve(loc)
                    }
                    else {
                        UseMessage(getTranslationByKey('Error!'))
                        resolve(loc)
                    }
                })
                .catch((err) => {
                    UseMessage(getTranslationByKey(err.message))
                    reject(err)
                })
        })
    }

    async setNotificationType(body) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/user/notification/modify', body).then(responseStatusNotification => {
                if (responseStatusNotification.status == 200) {
                    resolve()
                }
                else if (responseStatusNotification == 401) {
                    UseAlert(401)
                }
                else if (responseStatusNotification == 409) {
                    UseMessage(getTranslationByKey(responseStatusNotification.message))
                }
                else {
                    UseMessage(getTranslationByKey('Error!'))
                }
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        });
    }

    async getAllLocations() {
        let responseStatusLocations = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/desk_booking/v3/location', {
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(responseLocations => {
                responseStatusLocations = responseLocations.status
                return responseLocations.json()
            }).then(loc => {
                if (responseStatusLocations == 200) {
                    resolve(loc)
                }
                else if (responseStatusLocations == 401) {
                    UseAlert(401)
                    resolve(loc)
                }
                else if (responseStatusLocations == 409) {
                    UseMessage(getTranslationByKey(loc.message))
                    resolve(loc)
                }
                else {
                    UseMessage(getTranslationByKey('Error!'))
                    resolve(loc)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }

    async addBooking(toSend) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/desk_booking/v2/booking', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                },
                body: toSend
            }).then(response => {
                responseStatus = response.status
                if (responseStatus == 409)
                    return response.json()
                else
                    return response
            }).then(data => {
                if (responseStatus == 200) {
                    UseAlertMessage(200, getTranslationByKey('DESKBOOKING.RESERVATION.CONFIRMED'))
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else if (responseStatus == 409) {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey('Error!'))
                    resolve(data)
                }
            }).catch((err) => {

                reject(err)
            })
        })

    }

    async getLocationv2() {
        let responseStatusLocations = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/desk_booking/v3/location', {
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(responseLocations => {
                responseStatusLocations = responseLocations.status
                return responseLocations.json()
            }).then(async loc => {
                if (responseStatusLocations == 200) {
                    resolve(loc)
                }
                else if (responseStatusLocations == 401) {
                    UseAlert(401)
                    resolve(loc)
                }
                else if (responseStatusLocations == 409) {
                    UseMessage(getTranslationByKey(loc.message))
                    resolve(loc)
                }
                else {
                    UseMessage(getTranslationByKey(loc.message))
                    resolve(loc)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }

    async getFloorInfo(toSend) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/desk_booking/v2/floor', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                },
                body: toSend
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else if (responseStatus == 409) {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async deleteBooking(bookingID) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.delete(BASE_URL + '/desk_booking/booking/delete?id=' + bookingID).then(function (response) {
                if (response.status == 200) {
                    UseAlertMessage(200, getTranslationByKey('DESKBOOKING.RESERVATION.DELETED'))
                    resolve(response)
                }
                else if (response.status == 401) {
                    UseAlert(401)
                    resolve(response)
                }
                else if (response.status == 409) {
                    UseMessage(getTranslationByKey(response.message))
                    resolve(response)
                }
                else {
                    UseMessage(getTranslationByKey(response.message))
                    resolve(response)
                }

            }).catch(function (error) {
                UseMessage(getTranslationByKey(error.response.data.message))
                reject(error)
            });
        })
    }

    async updateUser(user_id, floor_id) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/desk_booking/v2/user/modify', {
                method: 'POST',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "user_id": user_id,
                    "floor_id": floor_id
                })
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                if (responseStatus == 200) {
                    UseAlertMessage(200, getTranslationByKey('DESKBOOKING.PROFILE.INFO.UPDATED'))
                    resolve()
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else if (responseStatus == 409) {
                    UseMessage(getTranslationByKey(data.message));
                }
                else {
                    UseMessage(getTranslationByKey(data.message));
                }
            }).catch((err) => {
                console.log(err)
                reject(err)
            });
        });
    }

    async updateUserDefault(user_id, location_id, floor_id) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/desk_booking/v2/user/modify', {
                method: 'POST',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "user_id": user_id,
                    "location_id": location_id,
                    "floor_id": floor_id
                })
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                if (responseStatus == 200) {
                    UseAlertMessage(200, getTranslationByKey('DESKBOOKING.PROFILE.INFO.UPDATED'))
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else if (responseStatus == 409) {

                    UseMessage(getTranslationByKey(data.message));
                    resolve(data)
                }
                else {

                    UseMessage(getTranslationByKey(data.message));
                    resolve(data)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }

    /* INFO LOCATION MENU */

    async getInfoLocation(id) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/v3/location/info/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.Message))
                    resolve(data)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }

    async getInfoLocationv2(id) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/v3/location/info/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            })
                .then(response => {
                    responseStatus = response.status
                    return response.json()
                })
                .then(data => {
                    if (responseStatus == 200) {
                        resolve(data)
                    }
                    else if (responseStatus == 401) {
                        UseAlert(401)
                        resolve(data)
                    }
                    else {
                        UseMessage(getTranslationByKey(data.Message))
                        resolve(data)
                    }
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    async getInfoLocationv4(id) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/v4/location/info/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            })
                .then(response => {
                    responseStatus = response.status
                    return response.json()
                })
                .then(data => {
                    if (responseStatus == 200) {
                        resolve(data)
                    }
                    else if (responseStatus == 401) {
                        UseAlert(401)
                        resolve(data)
                    }
                    else {
                        UseMessage(getTranslationByKey(data.Message))
                        resolve(data)
                    }
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }


    async getPagebyId(pageId) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/page/' + pageId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                if (responseStatus == 200) {

                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.Message))

                }
            }).catch((error) => {
                console.log(error)
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.Message));
                reject(error)
            })
        })
    }
}

export class CanteenService extends Component {

    // chiamata get per i dati di canteen
    async getCanteen(id) {
        let responseGetCanteen = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/canteen/' + id, {
                method: 'GET',
                headers: {
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                }
            }).then(response => {
                responseGetCanteen = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseGetCanteen == 200) {
                    resolve(data)
                } else if (responseGetCanteen == 401) {
                    UseAlert(401)
                    resolve(data)
                } else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }

    async getOccupancyStats(data, id) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/canteen/v2/occupancy/' + id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage
                },
                body: JSON.stringify({
                    "date": data,
                    "time_laps": 5
                })
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export class BookshelfService extends Component {

    async getInfoByIsbn(bookshelf_id, isbn) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            axios.post(BASE_URL + '/bookshelf/' + bookshelf_id + '/isbn', isbn, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'Language': navigator.language || navigator.userLanguage,
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                if (!data) {
                    resolve('not_found')
                }
                else if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else if (responseStatus == 409) {
                    UseMessage(getTranslationByKey(data.message));
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey('Error!'))
                    resolve(data);
                }

            }).catch((err) => {
                reject(err)
            })
        })
    }


    async getBookshelfByID(id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/bookshelf/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }
    async getAvailableBooksByID(id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/bookshelf/' + id + '/availablebooks', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getUnavailableBooksByID(id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/bookshelf/' + id + '/unavailablebooks', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async borrow(bookshelf_id, book_id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/bookshelf/' + bookshelf_id + '/borrow/' + book_id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                if (responseStatus == 200) {
                    UseAlertMessage(200, getTranslationByKey('BS.ALERT.BORROWED.SUCCESS'))
                    resolve(responseStatus)
                } else
                    return response.json()
            }).then(data => {
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async returnBook(borrow_id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/bookshelf/borrow/return/' + borrow_id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                if (responseStatus == 200) {
                    UseAlertMessage(200, getTranslationByKey('BS.ALERT.RETURNED.SUCCESS'))
                } else
                    return response.json()
            }).then(data => {
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })

    }

    async getBorrowHistory() {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/bookshelf/borrow/all', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getCurrentBorrow(setCurrentBorrow) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/bookshelf/borrow/current', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    setCurrentBorrow(data);
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async search(data, bookshelf_id, setBooks) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/bookshelf/' + bookshelf_id + '/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
                body: JSON.stringify(data)
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    setBooks(data);
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async searchV2(data, bookshelf_id) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/bookshelf/' + bookshelf_id + '/v2/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
                body: JSON.stringify(data)
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async searchAll(data, bookshelf_id) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/bookshelf/' + bookshelf_id + '/v2/search/all', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
                body: JSON.stringify(data)
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })

        })
    }

    async getCategories(bookshelf_id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/bookshelf/' + bookshelf_id + '/book/category', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getFilteredAvailableBooks(categoryID, bookshelfID, setBooks) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/bookshelf/' + bookshelfID + '/category/' + categoryID + '/available', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    setBooks(data);
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getFilteredUnavailableBooks(categoryID, bookshelfID, setBooks) {
        let responseStatus = ''
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/bookshelf/' + bookshelfID + '/category/' + categoryID + '/unavailable', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    setBooks(data);
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async notifyMe(bookshelfID, bookID) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/bookshelf/' + bookshelfID + '/book/' + bookID + '/notify', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
            }).then(response => {
                responseStatus = response.status
                if (responseStatus != 200)
                    return response.json()
            }).then(data => {
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })

    }
}

export class MeetingRoomsService extends Component {

    async getByLocationID(id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/meetingroom/location/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken()
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getFilter(id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/meetingroom/location/' + id + '/filter', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken()
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    setTimeout(() => {
                        resolve(data)
                    }, 1000)

                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getByID(id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/meetingroom/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken()
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async setFav(id) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/meetingroom/' + id + '/star', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                }
            }).then(response => {
                responseStatus = response.status
                if (responseStatus != 200)
                    return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    setTimeout(() => {
                        resolve(data)
                    }, 1000)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }


    async applyFilter(id, options) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/meetingroom/location/' + id + '/filter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken()
                },
                body: JSON.stringify(options)
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                } else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getAvailability(id, options) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/meetingroom/' + id + '/availability', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken()
                },
                body: JSON.stringify(options)
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }


    async getGuestList(searching) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/meetingroom/guest?search=' + searching, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken()
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                } else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async bookMeetingRoom(id, options) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/meetingroom/' + id + '/book', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken()
                },
                body: JSON.stringify(options)
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }
}

export class ReadyToGrowService extends Component {

    async getSlotsByDate(options) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/ready_to_grow/slots/date', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
                body: JSON.stringify(options)
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    data.map(async (u) => {
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + await getAccessToken()
                        axios.get('https://graph.microsoft.com/v1.0/users/' + u.hr.email + '/photo/$value', { responseType: 'blob' }).then((r) => {
                            var reader = new FileReader();
                            reader.readAsDataURL(r.data);
                            var i = ''
                            reader.onloadend = function () {
                                var base64data = reader.result;
                                i = base64data
                                u.hr.image_url = i
                            }
                        }).catch(function (error) {
                            if (error !== undefined && error.response !== undefined)
                                UseMessage(getTranslationByKey(error.response.data.message));
                            return ''
                        });
                    })
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async getSlots(options) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/ready_to_grow/slots', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
                body: JSON.stringify(options)
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }
    async getAdminSlots(options) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/ready_to_grow/admin/slots', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
                body: JSON.stringify(options)
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async bookSlot(slotID) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/ready_to_grow/book/' + slotID, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken(),
                }
            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    UseAlertMessage(200, getTranslationByKey(data.message))
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                } else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }

    async deleteSlot(slotID) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            let responseText = ''
            fetch(BASE_URL + '/ready_to_grow/book/' + slotID + '/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken(),
                }
            }).then(response => {
                responseStatus = response.status
                responseText = response.statusText
                return response.json()
            }).then(data => {
                // controlli response
                if (responseStatus == 200) {
                    UseAlertMessage(200, getTranslationByKey(data.message))
                    resolve(data)
                }
                else if (responseStatus == 401) {
                    UseAlert(401)
                    resolve(data)
                }
                else {
                    UseMessage(getTranslationByKey(data.message))
                    resolve(data)
                }
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }
    async addSlot(options) {
        return new Promise(async (resolve, reject) => {
            let responseStatus = ''
            fetch(BASE_URL + '/ready_to_grow/admin/slot/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken()
                },
                body: JSON.stringify(options)

            }).then(response => {
                responseStatus = response.status
                return response.json()
            }).then(response => {
                // controlli response
                UseAlertMessage(200, getTranslationByKey(response.message))
                resolve(response.code)

            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }
    async deleteAdminSlot(slotID) {
        return new Promise(async (resolve, reject) => {
            fetch(BASE_URL + '/ready_to_grow/admin/slot/delete/' + slotID, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getToken(),
                    'access_token': await getAccessToken()
                }
            }).then(response => {
                return response.json()
            }).then(response => {
                // controlli response
                UseAlertMessage(200, getTranslationByKey(response.message))
                resolve(response.code)
            }).catch((err) => {
                UseMessage(getTranslationByKey(err.message))
                reject(err)
            })
        })
    }
}

export class BusinessTravelService extends Component {

    async getDocument(docUrl, val) {
        return new Promise(async (resolve, reject) => {
            if (!docUrl) reject('error');

            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.get(docUrl, { responseType: 'blob' }).then((res) => {
                console.log(res)
                const type = window.innerWidth > 1000 ? 'application/pdf' : 'application/force-download'
                const blob = new Blob([res.data], { type: type })
                if (window.innerWidth <= 1000 || val == true) {
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.setAttribute('target', '_blank');
                    link.download = 'document.pdf'
                    link.click()
                }
                const fileReaderInstance = new FileReader();
                fileReaderInstance.readAsDataURL(blob);

                fileReaderInstance.onloadend = () => {
                    let base64data = fileReaderInstance.result.toString();
                    resolve(base64data)
                }
            }).catch((error) => {
                console.log(error)
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject('error')
            })
        })
    }
}

export class VrService extends Component {

    async getHeadsets(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/vr/headset/location/' + id).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getAllHeadsets(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/vr/headset/location/' + id + '/all').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getHeadsetDetail(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/vr/headset/' + id + '/details').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async borrowVr(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/vr/headset/' + id + '/borrow').then(function (response) {
                UseMessage(getTranslationByKey(response.data.message))
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async returnVr(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/vr/headset/' + id + '/return').then(function (response) {
                UseMessage(getTranslationByKey(response.data.message))
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
}

export class EventService extends Component {

    async getAgenda(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/event/' + id + '/v2/agenda').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getCommunications(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/event/' + id + '/communications').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    //tmp comment he brokes route /event/5/communications
                    //  UseMessage(getTranslationByKey(error.response.data.message));
                    UseAlertMessage(200, 'Communications not available')
                reject(error);
            });
        })
    }

    async getEventFilters(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/event/' + id + '/agenda/filters').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async filterEventAgenda(id, body) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/event/' + id + '/agenda/filter', body).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

}


export class FeedService extends Component {

    async getGroups() {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/feed/groups').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getFeedList() {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/feed').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getFeedListById(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/feed/groups/' + id).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getResourceById(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/feed/resource/' + id + '/downloadurl').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getGroupInfo(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/feed/groups/' + id + '/details').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async putViewResource(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/feed/resource/' + id + '/view').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

}

export class OpportunitiesService extends Component {

    async getAvailableOpportunity() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/v2/all').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getProjectManagerOpportunities() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/admin').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getWishlistOpportunity() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/wishlist').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getBookedOpportunity() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/booked').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getProject(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/projects/' + id).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getOpportunityById(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/' + id).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async addToWishlist(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/opportunities/wishlist/add/' + id).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async removeFromWishlist(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.delete(BASE_URL + '/opportunities/wishlist/remove/' + id).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getOpportunitiesTypes() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/types').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getOpportunitiesVisibilities() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/visibilities').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getUsersList(text) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/users?searchText=' + text).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async setPresent(opportunityID, id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/opportunities/' + opportunityID + '/booking/' + id + '/present').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }


    async setSelected(opportunityID, id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/opportunities/' + opportunityID + '/booking/' + id + '/select').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async setStarred(opportunityID, id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/opportunities/' + opportunityID + '/booking/' + id + '/starred').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async setMeeting(opportunityID, id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/opportunities/' + opportunityID + '/booking/' + id + '/meeting').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async bookOpportunity(id, body) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['access_token'] = await getAccessToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/opportunities/' + id + '/book', body).then(function (response) {
                resolve(response.data)
                UseMessage(getTranslationByKey(response.data.message));
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async createOpportunity(o) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/opportunities/', o).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async removeOpportunityBooking(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['access_token'] = await getAccessToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.delete(BASE_URL + '/opportunities/' + id + '/book').then(function (response) {
                resolve(response.data)
                UseMessage(getTranslationByKey(response.data.message));
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getLocationSearch(text) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/locations?searchText=' + text).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getListByType(type) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/filter/' + type).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async editOpportunity(opportunityID, body) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.put(BASE_URL + '/opportunities/', body).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async deleteOpportunity(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.delete(BASE_URL + '/opportunities/' + id).then(function (response) {
                resolve(response.data)
                UseMessage(getTranslationByKey(response.data.message));
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getFeedbackForm() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/feedback').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async sendFeedbackEmail(id, obj) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['access_token'] = await getAccessToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/opportunities/' + id + '/feedback/email', obj).then(function (response) {
                resolve(response.data)
                UseMessage(getTranslationByKey(response.data.message));
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async saveFeedback(body) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/opportunities/feedback', body).then(function (response) {
                UseMessage(getTranslationByKey(response.data.message))
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getInfoPageVideo(url) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(url).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getCreateInfo() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/opportunities/create').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
}

export class ShareToGoService extends Component {

    async getS2GOptions() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/share2go/options').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getS2GOptionsByID(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/share2go/options/' + id).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getRidesNoData() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/share2go/').then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getRides(date) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/share2go/?date=' + date).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }
    async getRidesByMonth(startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/share2go/?from=' + startDate + '&to=' + endDate).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async setEnableUser() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/share2go/enablement').then(function (response) {
                //UseMessage(getTranslationByKey(response.data.message))
                resolve(response.data)
            }).catch(function (error) {
                /**/
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async insertRide(body) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.post(BASE_URL + '/share2go/', body).then(function (response) {
                UseMessage(getTranslationByKey(response.data.message))
                resolve(response.data)
            }).catch(function (error) {
                /**/
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async deleteRide(id) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.delete(BASE_URL + '/share2go/' + id + '/').then(function (response) {
                resolve(response.data)
                UseMessage(getTranslationByKey(response.data.message));
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }


    async getDocumentLink(url) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(url).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.message));
                reject(error);
            });
        })
    }

    async getByDate(date, parkID) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/share2go/v3/byDate/' + parkID + '?date=' + date).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error.response)
                //if (error !== undefined && error.response !== undefined)
                //    UseMessage(getTranslationByKey(error.response.data.Message));
                reject(error);
            });
        })
    }


    async getRideDates(startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/share2go/rides/dates?from=' + startDate + '&to=' + endDate).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error.response)
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.Message));
                reject(error);
            });
        })
    }

    async getExcludedDatesByPark(parkID) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.defaults.headers.common['Language'] = navigator.language || navigator.userLanguage;
            axios.get(BASE_URL + '/share2go/options/dates/' + parkID).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error.response)
                if (error !== undefined && error.response !== undefined)
                    UseMessage(getTranslationByKey(error.response.data.Message));
                reject(error);
            });
        })
    }
}